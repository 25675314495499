<template>
 <div v-if="data" class="bg-white shadow overflow-hidden sm:rounded-lg">
  <div class="px-4 py-5 sm:px-6">
   <h3 class="text-lg leading-6 font-medium text-gray-900">
    {{ $t("resumeInformation") }}
   </h3>
  </div>
  <div v-if="data.element" class="border-t border-gray-200 px-4 py-5 sm:p-0">
   <dl class="sm:divide-y sm:divide-gray-200">
    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
     <dt class="text-sm font-medium text-gray-500">Statut</dt>
     <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
      {{ $t(data.element.status) }}
     </dd>
    </div>
    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("finishedAt") }}
     </dt>
     <dd class="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
      {{ data.element.finished_at ? $d(data.element.finished_at, "longNumb") : "Issue with date" }}
     </dd>
    </div>
    <!--   <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("contactsLength") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ data.contacts.length }}
          </dd>
        </div> -->
    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("requestIban") }}
     </dt>
     <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
      {{ data.element.request_iban ? $t("bankTransfer") : $t("other") }}
     </dd>
    </div>

    <!--     <div
          v-if="data.element.request_iban"
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("bankDetails") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul
              role="list"
              class="border border-gray-200 rounded-md divide-y divide-gray-200"
            >
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <span class="ml-2 flex-1">
                  {{
                    data.element.bank_details
                      ? data.element.bank_details.IBAN
                      : "FR 11 9000 3045 3456 0098 4242 J75"
                  }}
                </span>
              </li>
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <span class="ml-2 flex-1">
                  {{
                    data.element.bank_details
                      ? data.element.bank_details.BIC
                      : "CLSWIFT/BIC"
                  }}
                </span>
              </li>
            </ul>
          </dd>
        </div> -->
   </dl>
  </div>
 </div>
</template>

<script>
export default {
 props: ["data"],
 components: {},

 data() {
  return {};
 },
 methods: {},

 watch: {},
 mounted() {},
};
</script>
<style scoped></style>
