import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ThankYou from "../views/ThankYou.vue";
import store from "../store";

const routes = [
 {
  path: "/collect/:token",
  name: "Home",
  props: true,
  component: Home,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/thank-you",
  name: "ThankYou",
  component: ThankYou,
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/:pathMatch(.*)*",
  name: "404",
  component: () => import("../components/Error404.vue"),
  meta: {
   requiresAuth: true,
  },
 },
];

const router = createRouter({
 history: createWebHistory(process.env.BASE_URL),
 routes,
});

let entryUrl = null;

router.beforeEach((to, from, next) => {
 if (to.matched.some((record) => record.meta.requiresAuth)) {
  if (entryUrl) {
   const url = entryUrl;
   entryUrl = null;
   return next(url);
  }
  next();
  return;
  entryUrl = to.path;
  next("/");
 } else {
  next();
 }
});

router.beforeResolve((to, from, next) => {
 if (to.matched.some((record) => record.meta.requiresAuth)) {
  next();
  return;
  next("/");
 } else {
  next();
 }
});

router.beforeEach((to, from, next) => {
 if (to.matched.some((record) => record.meta.requiresNotLogged)) {
  next();
  return;
  next("/");
 } else {
  next();
 }
});

export default router;
