<template>
 <form @submit.prevent="this.$emit('validateForm')" method="POST">
  <div class="space-y-6 mb-5 bg-gray-50 sm:mx-5 sm:px-4">
   <div class="sm:border rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-4">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       Contacts
      </h3>
      <ul class="list-disc text-sm text-gray-500">
       <li v-for="(string, idx) in contactDescriptionList" :key="idx" class="ml-3">
        <span class="text-gray-700"> {{ $t(string.key) }} </span>: {{ $t(string.value) }}.
       </li>
      </ul>
     </div>

     <div class="mb-5 md:col-span-2 md:col-start-2">
      <div class="rounded-md bg-blue-50 px-4 py-2">
       <div class="flex">
        <div class="flex-shrink-0">
         <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
         <p class="text-sm text-blue-700">{{ $t("contactDetail") }}.</p>
        </div>
       </div>
      </div>
      <ul role="list" class="mt-3 grid grid-cols-1 gap-2 sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
       <li
        v-for="(contact, index) in contacts"
        :key="index"
        class="col-span-1 flex shadow-sm rounded-md"
       >
        <div
         class="bg-gray-50 flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate"
        >
         <div class="flex-1 px-4 py-2 text-sm truncate">
          <a
           @click="this.editNewContact(contact, index)"
           class="text-gray-900 font-medium hover:text-gray-600 cursor-pointer"
          >
           {{ contact.firstName }} {{ contact.lastName }}
          </a>
          <p class="text-gray-500 space-x-2 truncate">
           <span
            v-if="contact.is_signer_contract"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-gray-800"
           >
            {{ $t("isSignerContract") }}
           </span>
           <span
            v-if="contact.is_signer_iban"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-gray-800"
           >
            {{ $t("isSignerIban") }}
           </span>
           <span
            v-if="contact.is_collect_contact"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-gray-800"
           >
            {{ $t("collectContact") }}
           </span>
           <span
            v-if="contact.legalRepresentative"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800"
           >
            {{ $t("legalRepresentative") }}
           </span>
           <span
            v-if="contact.technicalContact"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800"
           >
            {{ $t("technical") }}
           </span>
           <span
            v-if="contact.invoicesRecipient"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800"
           >
            {{ $t("accounting") }}
           </span>
          </p>
         </div>
        </div>
       </li>
      </ul>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-1">
        <label for="civility" class="block text-sm font-medium text-gray-700">
         {{ $t("civility") }}
        </label>

        <div class="relative">
         <select
          v-model="this.form.civility"
          id="civility"
          name="civility"
          :class="[
           this.returnContactError('civility')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
            : ' border-gray-300 ',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]"
         >
          <option v-for="civility in civilities" :key="civility.id" :value="civility.id">
           {{ civility.name }}
          </option>
         </select>
         <div
          v-show="this.returnContactError('civility')"
          class="absolute inset-y-0 right-5 pr-3 flex items-center pointer-events-none"
         >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="this.returnContactError('civility')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ this.returnContactError("civility").error }}
        </p>
       </div>
       <div class="col-span-6 sm:col-span-2">
        <label for="firstName" class="block text-sm font-medium text-gray-700">
         {{ $t("firstName") }}
        </label>
        <div class="relative">
         <input
          v-model="this.form.firstName"
          type="text"
          name="signatory-firstName"
          id="signatory-firstName"
          :class="[
           this.returnContactError('firstName')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
            : ' border-gray-300 ',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]"
         />
         <div
          v-show="this.returnContactError('firstName')"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
         >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="this.returnContactError('firstName')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ this.returnContactError("firstName").error }}
        </p>
       </div>

       <div class="col-span-6 sm:col-span-2">
        <label for="lastName" class="block text-sm font-medium text-gray-700">
         {{ $t("lastName") }}
        </label>
        <div class="relative">
         <input
          v-model="this.form.lastName"
          type="text"
          name="signatory-firstName"
          id="signatory-firstName"
          :class="[
           this.returnContactError('lastName')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
            : ' border-gray-300 ',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]"
         />
         <div
          v-show="this.returnContactError('lastName')"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
         >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="this.returnContactError('lastName')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ this.returnContactError("lastName").error }}
        </p>
       </div>

       <div class="col-span-6 sm:col-span-4">
        <label for="email" class="block text-sm font-medium text-gray-700">
         E-mail
        </label>
        <div class="relative">
         <input
          v-model="this.form.emailAddress"
          @change="checkEmailValid(this.form.emailAddress)"
          type="email"
          name="email"
          id="email"
          autocomplete="email"
          :class="[
           errorEmail || this.returnContactError('email')
            ? 'placeholder-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 pr-10'
            : 'border-gray-300',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]"
          :aria-invalid="!emailValidator(this.form.emailAddress)"
          aria-describedby="email-error"
         />
         <div
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          v-show="errorEmail || this.returnContactError('email')"
         >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="errorEmail || this.returnContactError('email')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{
          errorEmail
           ? $t("emailErrorFormat") + " johndoe@example.com"
           : this.returnContactError("email").error
         }}
        </p>
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="phone-home" class="block text-sm font-medium text-gray-700">
         {{ $t("phoneNumber") }}**
         <span class="text-xs font-medium italic text-gray-500">
          (Vous devez nous renseigner un téléphone mobile
          <span class="font-semibold">ou</span> un téléphone fixe).
         </span>
        </label>
        <div class="mt-1 relative rounded-md">
         <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center">
           <label for="country" class="sr-only">Country</label>
           <select
            id="country"
            name="country"
            class="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
           >
            <option>FR</option>
           </select>
          </div>

          <input
           v-model="this.form.landlinePhone"
           type="tel"
           name="phone-home"
           id="phone-home"
           :class="[
            this.returnContactError('landlinePhone')
             ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
             : 'border-gray-300',
            'block w-full pl-20 sm:text-sm rounded-md shadow-sm',
           ]"
           placeholder="04 87 65 43 21"
          />
          <div
           v-show="this.returnContactError('landlinePhone')"
           class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
           <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <p
          v-if="this.returnContactError('landlinePhone')"
          class="ml-1 text-sm text-red-600"
          id="email-error"
         >
          {{ $t("phone") }} {{ $t("or") }} {{ $t("mobileNumber") }}
          {{ $t("cantBeEmpty") }}
         </p>
        </div>
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="phone-home" class="block text-sm font-medium text-gray-700">
         {{ $t("mobileNumber") }}**
         <span class="text-xs font-medium italic text-gray-500">
          (Vous devez nous renseigner un téléphone mobile
          <span class="font-semibold">ou</span> un téléphone fixe).
         </span>
        </label>
        <div class="mt-1 relative rounded-md">
         <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center">
           <label for="country" class="sr-only">Country</label>
           <select
            id="country"
            name="country"
            class="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
           >
            <option>FR</option>
           </select>
          </div>

          <input
           v-model="this.form.mobilePhone"
           type="tel"
           name="phone-home"
           id="phone-home"
           :class="[
            this.returnContactError('mobilePhone')
             ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
             : 'border-gray-300',
            'block w-full pl-20 sm:text-sm rounded-md shadow-sm',
           ]"
           placeholder="06 78 56 34 12"
          />
          <div
           v-show="this.returnContactError('mobilePhone')"
           class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
           <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <p
          v-if="this.returnContactError('mobilePhone')"
          class="ml-1 text-sm text-red-600"
          id="email-error"
         >
          {{ $t("phone") }} {{ $t("or") }} {{ $t("mobileNumber") }}
          {{ $t("cantBeEmpty") }}
         </p>
        </div>
       </div>

       <div class="col-span-6">
        <div class="grid grid-cols-3 relative flex items-start gap-y-4">
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.is_signer_contract"
            id="signerContract"
            aria-describedby="signerContract-description"
            name="signerContract"
            type="checkbox"
            class="h-4 w-4 encom_ouvert_ticket_text border-gray-300 rounded"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="signerContract" class="font-medium text-gray-700">
            {{ $t("isSignerContract") }}
           </label>
          </div>
         </div>
         <div v-if="element ? element.request_iban : true" class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.is_signer_iban"
            id="signerIban"
            aria-describedby="signerIban-description"
            name="signerIban"
            type="checkbox"
            class="h-4 w-4 encom_ouvert_ticket_text border-gray-300 rounded"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="signerIban" class="font-medium text-gray-700">
            {{ $t("isSignerIban") }}
           </label>
          </div>
         </div>
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.legalRepresentative"
            id="legalRepresentative"
            aria-describedby="legalRepresentative-description"
            name="legalRepresentative"
            type="checkbox"
            class="h-4 w-4 encom_ouvert_ticket_text border-gray-300 rounded"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="legalRepresentative" class="font-medium text-gray-700">
            {{ $t("legalRepresentative") }}
           </label>
          </div>
         </div>
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.is_collect_contact"
            id="collectContact"
            aria-describedby="collectContact-description"
            name="collectContact"
            type="checkbox"
            class=" h-4 w-4 encom_ouvert_ticket_text border-gray-300 rounded"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="collectContact" class="font-medium text-gray-700">
            {{ $t("collectContact") }}
           </label>
          </div>
         </div>
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.technicalContact"
            id="technicalContact"
            aria-describedby="technicalContact-description"
            name="technicalContact"
            type="checkbox"
            class=" h-4 w-4 encom_ouvert_ticket_text border-gray-300 rounded"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="technicalContact" class="font-medium text-gray-700">
            {{ $t("technicalContact") }}
           </label>
          </div>
         </div>
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.invoicesRecipient"
            id="invoicesRecipient"
            aria-describedby="invoicesRecipient-description"
            name="invoicesRecipient"
            type="checkbox"
            class=" h-4 w-4 encom_ouvert_ticket_text border-gray-300 rounded"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="invoicesRecipient" class="font-medium text-gray-700">
            {{ $t("invoicesRecipient") }}
           </label>
          </div>
         </div>
        </div>

        <p
         v-if="
          this.returnContactError('contact') ||
           (errors.infos.length > 0 && errors.infos[0].match('signataire'))
         "
         class="mt-2 text-sm text-red-600 flex"
         id="email-error"
        >
         <ExclamationCircleIcon class="h-5 w-5 text-red-500 mr-2" aria-hidden="true" />

         {{
          errors.infos.length > 0 && errors.infos[0].match("signataire")
           ? errors.infos[0]
           : this.returnContactError("contact").error
         }}
        </p>
       </div>
      </div>

      <div class="flex justify-end px-4">
       <button
        v-if="this.isContactEdited"
        @click="this.clearContactForm()"
        class="bg-white py-2 px-4 mr-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none   "
       >
        {{ $t("cancel") }}
       </button>
       <button
        v-if="this.isContactEdited"
        @click="editContact()"
        type="button"
        class="encom_primary encom_primary_hover py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-gray-50 focus:outline-none"
       >
        {{ $t("save") }}
       </button>

       <button
        v-else
        @click="addContact()"
        type="button"
        class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover encom_ring_color"
       >
        {{ $t("save") }}
       </button>
      </div>
     </div>
    </div>
   </div>
   <div v-if="element ? element.request_iban : true" class="sm:border rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-4">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("bankDetails") }}
      </h3>
      <p class="mt-1 text-sm text-gray-600">{{ $t("bankDetailsDescription") }}.</p>
     </div>

     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
      <CollectImportFiles
       :validationErrors="errors"
       :token="this.token"
       @set-loading="this.$emit('setLoading', $event)"
       @active-banner="this.$emit('activeBanner', $event)"
       @success="this.$emit('success', $event)"
       @success-msg="this.$emit('successMsg', $event)"
      />
     </div>

     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-5">
        <div>
         <dl class="divide-y divide-gray-200">
          <div class="flex py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
           <dt
            :class="[
             errorOnFinalFormIBAN ? 'self-start mt-1' : 'self-center',
             'text-sm font-medium text-gray-700',
            ]"
           >
            IBAN
           </dt>

           <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!updateIBAN" :class="bankDetails ? 'flex-grow' : 'flex-grow capitalize'">
             {{ bankDetails ? bankDetails.IBAN : $t("none") }}
            </span>
            <div v-else class="flex-grow">
             <div class="relative">
              <input
               v-model="bank_details.IBAN"
               type="text"
               name="IBAN"
               id="IBAN"
               @keyup="errorOnFinalFormIBAN = false"
               :class="[
                errorOnFinalFormIBAN
                 ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
                 : '',
                'block w-full h-7 rounded-md bg-transparent sm:text-sm focus:z-10 sm:text-sm border-gray-300',
               ]"
               placeholder="FR00 0000 0000 00XX XXXX XXXX X00"
              />
              <div
               v-if="errorOnFinalFormIBAN"
               class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
               <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
             </div>
             <p
              v-if="errorOnFinalFormIBAN"
              class="ml-1 text-sm text-red-600"
              id="errorOnFinalFormIBAN"
             >
              <!-- {{$t("errorOnFinalFormIBAN") }} -->

              {{
               errors.infos && errors.infos[0].match("IBAN") ? errors.infos[0] : errors.infos[1]
              }}
             </p>
            </div>
            <div
             :class="[
              errorOnFinalFormIBAN || errors.bool ? 'items-start mt-1' : 'items-center',
              'ml-4 flex-shrink-0 flex space-x-4',
             ]"
            >
             <button
              v-if="!updateIBAN"
              @click="triggerUpdateIBAN()"
              type="button"
              class="bg-white rounded-md font-medium primary_text_dark encom_primary_text_dark_hover"
             >
              {{ $t("edit") }}
             </button>

             <button
              v-else
              @click="updateIBAN = false"
              type="button"
              class="bg-white rounded-md font-medium primary_text_dark encom_primary_text_dark_hover"
             >
              {{ $t("cancel") }}
             </button>
            </div>
           </dd>
          </div>
          <div class="flex py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
           <dt
            :class="[
             errorOnFinalFormBIC ? 'self-start mt-1' : 'self-center',
             'text-sm font-medium text-gray-700',
            ]"
           >
            SWIFT/BIC
           </dt>
           <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!updateBIC" :class="bankDetails ? 'flex-grow' : 'flex-grow capitalize'">
             {{ bankDetails ? bankDetails.BIC : $t("none") }}
            </span>
            <div v-else class="flex-grow">
             <div class="relative">
              <input
               v-model="bank_details.BIC"
               type="text"
               name="IBAN"
               id="IBAN"
               maxlength="11"
               @keyup="errorOnFinalFormBIC = false"
               :class="[
                errorOnFinalFormBIC
                 ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
                 : '',
                'block w-full h-7 rounded-md bg-transparent sm:text-sm focus:z-10 sm:text-sm border-gray-300',
               ]"
               placeholder="SWIFT/BIC"
              />
              <div
               v-if="errorOnFinalFormBIC"
               class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
               <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
             </div>
             <p
              v-if="errorOnFinalFormBIC"
              class="ml-1 text-sm text-red-600"
              id="errorOnFinalFormIBAN"
             >
              <!-- {{ $t("errorOnFinalFormBIC") }}. -->
              {{ errors.infos[0].match("BIC") ? errors.infos[0] : errors.infos[1] }}
             </p>
            </div>
            <div
             :class="[
              errorOnFinalFormBIC || errors.bool ? 'items-start mt-1' : 'items-center',
              'ml-4 flex-shrink-0 flex space-x-4',
             ]"
            >
             <button
              v-if="!updateBIC"
              @click="triggerUpdateBIC()"
              type="button"
              class="bg-white rounded-md font-medium primary_text_dark encom_primary_text_dark_hover"
             >
              {{ $t("edit") }}
             </button>

             <button
              v-else
              @click="updateBIC = false"
              type="button"
              class="bg-white rounded-md font-medium primary_text_dark encom_primary_text_dark_hover"
             >
              {{ $t("cancel") }}
             </button>
            </div>
           </dd>
          </div>
         </dl>
        </div>
       </div>
       <div v-if="iban_file" class="col-span-6 sm:col-span-5 sm:grid sm:grid-cols-3 sm:gap-4 ">
        <dt class="text-sm font-medium text-gray-700">{{ $t("attachments") }}</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
         <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
          <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
           <div class="flex w-0 flex-1 items-center">
            <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <span class="ml-2 w-0 flex-1 truncate"> {{ iban_file.fileName }} </span>
           </div>
          </li>
         </ul>
        </dd>
       </div>
       <div class="col-span-6 flex justify-end px-4">
        <button
         @click="updateBankDetails()"
         type="button"
         class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover encom_ring_color"
        >
         {{ $t("save") }}
        </button>
       </div>
      </div>
     </div>
    </div>
   </div>
   <div class="hidden sm:block" aria-hidden="true">
    <div v-if="element ? element.updated_at : ''" class="mb-2">
     <p class="text-sm text-gray-700">
      {{ $t("informationWasUpdated") }}
      <span class="capitalize text-gray-500">
       {{ $d(element.updated_at, "longNumb") }}
      </span>
     </p>
    </div>
    <div class="pb-5">
     <div class="border-t border-gray-200" />
    </div>
   </div>
   <div class="pb-5 flex justify-end px-4">
    <div v-show="errorOnFinalFormContact" class="rounded-md bg-red-50 p-4 w-3/5">
     <div class="flex">
      <div class="flex-shrink-0">
       <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
       <p class="text-sm font-semibold text-red-800">
        {{ $t("thereIs") }} {{ $t("oneOrMore") }} {{ $t("errorWithYourSubmission") }}.
        <span class="font-normal">
         {{ errorOnFinalFormContact ? $t("needAtLeastOneContact") : $t("needAtLeastOneAddress") }}
        </span>
       </p>
      </div>
     </div>
    </div>

    <div class="flex justify-end">
     <button
      type="submit"
      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button"
     >
      {{ $t("validate") }}
     </button>
    </div>
   </div>
  </div>
 </form>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import ToggleWithIcon from "./formTools/ToggleWithIcon";
import Tooltip from "./formTools/Tooltip";
import CollectImportFiles from "./CollectImportFiles.vue";

import {
 Listbox,
 ListboxButton,
 ListboxLabel,
 ListboxOption,
 ListboxOptions,
 Switch,
 SwitchDescription,
 SwitchGroup,
 SwitchLabel,
} from "@headlessui/vue";
import {
 CheckIcon,
 InformationCircleIcon,
 ExclamationCircleIcon,
 SelectorIcon,
 PaperClipIcon,
 PlusSmIcon,
 MinusSmIcon,
 TrashIcon,
 XCircleIcon,
} from "@heroicons/vue/solid";
const civilities = [
 { id: 1, name: "Mademoiselle" },
 { id: 2, name: "Madame" },
 { id: 3, name: "Monsieur" },
 { id: 4, name: "Monsieur et Madame" },
 { id: 5, name: "Docteur" },
];
export default {
 name: "CollectMainInfos",
 props: ["contacts", "bankDetails", "element", "valid", "errors", "token", "iban_file"],
 components: {
  CheckIcon,
  CollectImportFiles,
  InformationCircleIcon,
  ExclamationCircleIcon,
  MinusSmIcon,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  PaperClipIcon,
  PlusSmIcon,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  SelectorIcon,
  TrashIcon,
  Tooltip,
  ToggleWithIcon,
  XCircleIcon,
 },
 setup() {
  const enabledSignatory = ref(false);
  const enabledTechinal = ref(true);
  const enabledAccountance = ref(true);
  const selected = ref(civilities[0]);
  return {
   civilities,
   enabledSignatory,
   enabledTechinal,
   enabledAccountance,
   selected,
  };
 },
 data() {
  return {
   activateIban: false,
   bank_details: { IBAN: "", BIC: "" },
   contactEditIndex: null,
   contactError: false,
   contactErrors: {
    number: null,
    lists: [],
   },
   errorOnFinalFormContact: false,
   errorOnFinalFormIBAN: false,
   errorOnFinalFormBIC: false,
   errorEmail: false,
   form: {
    civility: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    landlinePhone: "",
    mobilePhone: "",
    invoice: false,
    legalRepresentative: false,
    technicalContact: false,
    invoicesRecipient: false,
    is_collect_contact: false,
    is_signer_contract: false,
    is_signer_iban: false,
    admin: false,
   },
   contactDescriptionList: [
    { key: "contactDetail1", value: "contactDetailList1" },
    { key: "contactDetail2", value: "contactDetailList2" },
    { key: "contactDetail3", value: "contactDetailList3" },
    { key: "contactDetail4", value: "contactDetailList4" },
    { key: "contactDetail5", value: "contactDetailList5" },
   ],
   isContactEdited: false,
   objKey: 0,
   objKeyContact: 0,
   showForm: false,
   updateIBAN: false,
   updateBIC: false,
  };
 },

 methods: {
  errorContactForm(contact) {
   var i = 0;
   this.contactError = true;
   this.contactErrors.lists = [];
   var absoluteNumb = contact.mobilePhone ? contact.mobilePhone.split(" ").join("") : "";
   const isnum = /^\d+$/.test(absoluteNumb);
   if (!this.emailValidator(this.form.emailAddress) && !this.form.emailAddress) {
    i = 1;

    var key = this.$t("theEmail") + " " + this.$t("cantBeEmpty");
    const objValue = {
     type: "email",
     error: key,
    };
    this.contactErrors.lists.push(objValue);

    this.contactErrors.number = i;
   } else if (!this.emailValidator(this.form.emailAddress)) {
    i = 1;

    var key = this.$t("emailErrorFormat") + " " + "johndoe@example.com";
    const objValue = {
     type: "email",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   } else if (this.emailFinder(this.form.emailAddress, this.contactEditIndex)) {
    i = 1;

    var key = this.$t("emailErrorAlreadyExist");
    const objValue = {
     type: "email",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   }

   if (
    (!contact.is_collect_contact && !contact.is_signer_contract && !contact.is_signer_iban) ||
    (!contact.invoicesRecipient && !contact.legalRepresentative && !contact.technicalContact)
   ) {
    i = 1;

    var key = "Un type de contact doit être sélectionné";
    const objValue = {
     type: "contact",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   } else if (
    !contact.invoicesRecipient &&
    !contact.legalRepresentative &&
    !contact.technicalContact
   ) {
    i = 1;

    var key = "Un type de contact doit être sélectionné (signataire, technique ou comptabilité)";
    const objValue = {
     type: "contact",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   }

   if (
    (!isnum && absoluteNumb.length > 0) ||
    (absoluteNumb.length < 10 && absoluteNumb.length > 0)
   ) {
    i = 1;

    var key = "Le numéro de téléphone doit comporter au moins 10 chiffres";
    const objValue = {
     type: "phone",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   }

   for (const key in contact) {
    if (Object.hasOwnProperty.call(contact, key)) {
     const element = contact[key];
     if (
      (key === "civility" && !element) ||
      (key === "firstName" && !element) ||
      (key === "lastName" && !element)
     ) {
      i = i + 1;
      const objValue = {
       type: key,
       error: this.$t(key) + " " + this.$t("cantBeEmpty"),
      };
      this.contactErrors.lists.push(objValue);
     }
     if (key === "landlinePhone" && !element) {
      if (!contact["mobilePhone"]) {
       i = i + 1;
       const objValue = {
        type: key,
        error: this.$t(key) + " " + this.$t("cantBeEmpty"),
       };
       this.contactErrors.lists.push(objValue);
      }
     }
     if (key === "mobilePhone" && !element) {
      if (!contact["landlinePhone"]) {
       i = i + 1;
       const objValue = {
        type: key,
        error: this.$t(key) + " " + this.$t("cantBeEmpty"),
       };
       this.contactErrors.lists.push(objValue);
      }
     }
    }
   }
   this.contactErrors.number = i;
  },
  returnContactError(errType) {
   const filtered = this.contactErrors.lists.filter(function(err) {
    return err.type === errType;
   });
   return filtered[0];
  },
  checkEmailValid(email) {
   email && !this.emailValidator(email) ? (this.errorEmail = true) : (this.errorEmail = false);
  },
  emailValidator(email) {
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
   }
   return false;
  },
  emailFinder(email, id) {
   return this.isContactEdited
    ? this.contacts.some((obj) => obj.emailAddress === email && obj.id !== id)
    : this.contacts.some((obj) => obj.emailAddress === email);
  },

  addContact() {
   if (
    this.form.civility &&
    this.form.firstName &&
    this.form.lastName &&
    this.form.emailAddress &&
    (this.form.landlinePhone || this.form.mobilePhone) &&
    !this.emailFinder(this.form.emailAddress) &&
    this.emailValidator(this.form.emailAddress)
   ) {
    const newContact = [{ ...this.form }];

    this.$emit("sendForm", newContact, this.bank_details);

    this.clearContactForm();
   } else {
    this.errorContactForm(this.form);
   }
  },
  editNewContact(contact) {
   this.isContactEdited = true;
   this.showForm = true;
   this.contactEditIndex = contact.id;
   this.form.invoice = contact.invoice;
   this.form.civility = contact.civility;
   this.form.firstName = contact.firstName;
   this.form.lastName = contact.lastName;
   this.form.emailAddress = contact.emailAddress;
   this.form.landlinePhone = contact.landlinePhone;
   this.form.mobilePhone = contact.mobilePhone;
   this.form.is_signer_contract = contact.is_signer_contract;
   this.form.is_signer_iban = contact.is_signer_iban;

   contact.is_collect_contact === 1
    ? (this.form.is_collect_contact = true)
    : (this.form.is_collect_contact = false);
   contact.legalRepresentative === 1
    ? (this.form.legalRepresentative = true)
    : (this.form.legalRepresentative = false);
   contact.technicalContact === 1
    ? (this.form.technicalContact = true)
    : (this.form.technicalContact = false);
   contact.invoicesRecipient === 1
    ? (this.form.invoicesRecipient = true)
    : (this.form.invoicesRecipient = false);
  },
  editContact() {
   if (
    this.form.civility &&
    this.form.firstName &&
    this.form.lastName &&
    this.form.emailAddress &&
    (this.form.landlinePhone || this.form.mobilePhone) &&
    !this.emailFinder(this.form.emailAddress, this.contactEditIndex) &&
    this.emailValidator(this.form.emailAddress)
   ) {
    const editContact = [
     {
      id: this.contactEditIndex,
      civility: this.form.civility,
      firstName: this.form.firstName,
      lastName: this.form.lastName,
      emailAddress: this.form.emailAddress,
      landlinePhone: this.form.landlinePhone,
      mobilePhone: this.form.mobilePhone,
      legalRepresentative: this.form.legalRepresentative,
      technicalContact: this.form.technicalContact,
      invoicesRecipient: this.form.invoicesRecipient,
      is_signer_contract: this.form.is_signer_contract,
      is_collect_contact: this.form.is_collect_contact,
      is_signer_iban: this.form.is_signer_iban,
     },
    ];

    this.$emit("sendForm", editContact, this.bank_details);

    this.clearContactForm();
   } else {
    this.errorContactForm(this.form);
   }
  },
  updateBankDetails() {
   this.errorOnFinalFormIBAN = false;
   this.errorOnFinalFormBIC = false;

   this.bank_details.IBAN = this.bank_details.IBAN.replace(/\s/g, "");
   this.$emit("sendForm", [], this.bank_details);
   this.clearContactForm();
  },
  clearContactForm() {
   this.contactError = false;
   this.errorEmail = false;
   this.contactErrors.lists = [];
   this.form.invoice = false;
   this.form.firstName = "";
   this.form.lastName = "";
   this.form.emailAddress = "";
   this.form.landlinePhone = "";
   this.form.mobilePhone = "";
   this.form.legalRepresentative = false;
   this.form.technicalContact = false;
   this.form.invoicesRecipient = false;
   this.form.is_signer_contract = false;
   this.form.is_collect_contact = false;
   this.form.is_signer_iban = false;
   this.isContactEdited = false;
   this.updateBIC = false;
   this.updateIBAN = false;
  },
  formatIban(string) {
   let str = string.split(" ").join("");
   if (string.length > 0) {
    str = str.match(new RegExp(".{1,4}", "g")).join(" ");
   }
   //fr15 3000 2028 3400 0034 7620 J75
   //FR1190003045345600984242J75

   let result = string.replace(
    /^(.{2})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})$/,
    "$1 $2 $3 $4 $5 $6 $7"
   );
   this.bank_details.IBAN = result;
  },
  triggerUpdateIBAN() {
   this.updateIBAN = true;
   this.bank_details.IBAN = this.bankDetails.IBAN;
  },
  triggerUpdateBIC() {
   this.updateBIC = true;
   this.bank_details.BIC = this.bankDetails.BIC;
  },
  async getFile(id, mimeType) {
   try {
    this.$emit("setLoading", true);
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/documents/${id}/content`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     responseType: "arraybuffer",
    });
    const file = new Blob([await res.data], { type: mimeType });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
   } catch (error) {
    console.error(error);
    this.errorHandling(error);
   } finally {
    this.$emit("setLoading", false);
   }
  },
 },
 created() {},
 mounted() {
  if (this.bankDetails) {
   this.bank_details.IBAN = this.bankDetails.IBAN;
   this.bank_details.BIC = this.bankDetails.BIC;
  }
 },
 watch: {
  "errors.bool": {
   handler: function(bool) {
    if (bool) {
     for (let index = 0; index < this.errors.infos.length; index++) {
      const err = this.errors.infos[index];

      if (err.match("IBAN") && err.match("IBAN").length > 0) {
       this.updateIBAN = true;
       this.errorOnFinalFormIBAN = true;
      }
      if (err.match("BIC") && err.match("BIC").length > 0) {
       this.updateBIC = true;
       this.errorOnFinalFormBIC = true;
      }
     }
    }
   },
   deep: true,
  },
 },
};
</script>

<style></style>
