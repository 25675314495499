<template>
 <MainHeader />
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess"
 />

 <router-view
  :key="$route.fullPath"
  @set-loading="isLoading = $event"
  @active-banner="(activeBanner = true), (key = !key)"
  @success-msg="successMsg = $event"
  @success="isSuccess = $event"
 />
 <Loading v-model:active="isLoading" :can-cancel="true" :is-full-page="true" loader="dots" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MainHeader from "./components/MainHeader.vue";
import SuccessBanner from "./components/SuccessBanner.vue";

export default {
 title() {
  return `${localStorage.getItem("title")}`;
 },
 components: { MainHeader, SuccessBanner, Loading },
 data() {
  return {
   activeBanner: false,
   isSuccess: true,
   isLoading: false,
   key: false,
   successMsg: "",
  };
 },
 methods: {
  async getApi() {
   try {
    axios.post("https://air-traffic.bluerocktel.net/api/flights").then((res) => {
     console.log(res.data[0]);
     let apiData = "";
     if (Object.keys(res.data[0]).length > 1) {
      apiData = res.data[0].encom_admin_production;
      //apiData = res.data[0].encom_admin_sanbox;
      localStorage.setItem("isLocal", true);
     } else {
      apiData = res.data[0].default;
     }
     if (apiData && apiData.active) {
      //this.initIcon(apiData.logo);
      localStorage.setItem("logo", apiData.logo);
      this.$store.dispatch("logo", apiData.logo);
      localStorage.setItem("title", apiData.title);
      if (!localStorage.getItem("language")) {
       localStorage.setItem("language", apiData.lang);
      }
      if (!this.$cookie.getCookie("API") || this.$cookie.getCookie("API") !== apiData.baseUrl) {
       this.$cookie.setCookie("API", apiData.baseUrl, {
        expire: "21d",
       });
      }
     }
    });
   } catch (error) {
    this.errorHandling(error);
   }
  },
 },
 mounted() {
  this.getApi();
 },
 computed: {
  ...mapGetters(["token"]),
 },
};
</script>

<style></style>
