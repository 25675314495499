<template>
 <div v-if="errorStatus">
  <Error404 :msg="errorMsg" :status="errorStatus" />
 </div>
 <div v-else>
  <HeaderSection
   title="customerInformationCollection"
   header="homePageHeader"
   headerDescription="homePageHeaderDescription"
  />
  <CollectMainInfos
   :contacts="data.contacts"
   :bankDetails="data.bank_details ? data.bank_details : ''"
   :element="data.element"
   :iban_file="data.iban_file"
   :valid="valid"
   :token="this.token"
   :errors="errors"
   @set-loading="this.$emit('setLoading', $event)"
   @active-banner="this.$emit('activeBanner', $event), getData(this.token)"
   @success="this.$emit('success', $event)"
   @success-msg="this.$emit('successMsg', $event)"
   @send-form="(...args) => sendData(...args)"
   @validate-form="validateForm(this.token)"
  />
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"> </ask-confirmation-dialog>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

import AskConfirmationDialog from "../components/AskConfirmationDialog";
import CollectMainInfos from "../components/CollectMainInfos";
import Error404 from "../components/Error404.vue";
import HeaderSection from "../components/HeaderSection";

export default {
 name: "Home",
 props: ["token"],
 components: {
  AskConfirmationDialog,
  CollectMainInfos,
  Error404,
  HeaderSection,
 },

 data() {
  return {
   data: {},
   errors: { bool: false, infos: [] },
   errorStatus: null,
   errorMsg: "",
   valid: false,
  };
 },
 methods: {
  async getData(token) {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/customer-information-collection/${token}`
    );
    this.data = res.data;
   } catch (error) {
    this.errorMsg = error.response.statusText;
    this.errorStatus = error.response.status;
    console.error(error.response);
   } finally {
    this.$emit("setLoading", false);
   }
  },
  sendData(...args) {
   this.errors.bool = false;
   let contactsArr = args[0];
   let bankDetailsObj = args[1];

   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/customer-information-collection/${this.token}`,
    data: { contacts: contactsArr, bank_details: bankDetailsObj },
    headers: {
     "Content-Type": "application/json",
    },
   };
   axios
    .request(options)
    .then((res) => {
     let msg = this.$t("requestSuccess");

     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);

     this.getData(this.token);
    })
    .catch((error) => {
     console.error(error.response);
     this.errors.bool = true;
     error.response.data.infos ? (this.errors.infos = [...error.response.data.infos]) : "";

     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", error.response.statusText);
    });
  },
  async validateForm(token) {
   this.$emit("setLoading", true);
   const options = {
    method: "GET",
    url: `${this.$cookie.getCookie(
     "API"
    )}/api/v1/customer-information-collection/${token}/validate`,
    headers: {
     "Content-Type": "application/json",
    },
   };
   axios
    .request(options)
    .then((res) => {
     this.errors.bool = false;
     this.$emit("setLoading", false);
     this.valid = res.data.valid;

     if (this.valid) {
      this.key = !this.key;
      this.getData(this.token);
      this.closeForm(this.token);
     } else {
      this.errorOnValidation();
     }
    })
    .catch((error) => {
     console.error(error);

     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", error.response.data.message);
    });
  },
  errorOnValidation() {
   console.log(this.data.contacts.length);
   this.errors.bool = true;
   this.errors.infos = [];
   if (this.data.contacts.length < 1) {
    let err = "errorMissingContact";
    this.errors.infos = [...this.errors.infos, err];
   }
   if (!this.data.bank_details) {
    let err = "errorMissingBankDetail";
    this.errors.infos = [...this.errors.infos, err];
   }
   if (!this.data.iban_file) {
    let err = "errorMissingImportFile";
    this.errors.infos = [...this.errors.infos, err];
   }
  },

  async closeForm(token) {
   const title = "closeCollectPopupTitle";
   const confirmationMessage = "closeCollectPopupMessage";
   const confirmButton = "validate";
   const goBack = "cancel";
   let isSignerContract = this.data.contacts.find((o) => o.is_signer_contract);
   let isSignerIban = this.data.contacts.find((o) => o.is_signer_iban);
   let isTecnicalContact = this.data.contacts.find((o) => o.technicalContact);
   this.errors.infos = [];
   if (!isSignerContract) {
    let errC = "Un signataire du contrat doit être obligatoire";
    this.errors.bool = true;
    this.errors.infos = [...this.errors.infos, errC];
   } else if (this.data.element.request_iban === 1 && !isSignerIban) {
    let errI = "Un signataire de l'IBAN doit être obligatoire";

    this.errors.bool = true;
    this.errors.infos = [...this.errors.infos, errI];
   } else if (!isTecnicalContact) {
    let errT = "Un contact technique doit être obligatoire";

    this.errors.bool = true;
    this.errors.infos = [...this.errors.infos, errI];
   } else {
    const options = {
     method: "GET",
     url: `${this.$cookie.getCookie("API")}/api/v1/customer-information-collection/${token}/close`,
     headers: {
      "Content-Type": "application/json",
     },
    };
    const r = await this.$refs.askConfirmationDialog.show({
     title: title,
     message: confirmationMessage,
     okButton: confirmButton,
     cancelButton: goBack,
     action: "validate",
    });
    if (r) {
     axios
      .request(options)
      .then((res) => {
       let msg = this.$t("thanksForValidation");

       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);

       this.$router.push({
        name: "ThankYou",
        params: {
         closingCollect: JSON.stringify(res.data),
        },
       });
      })
      .catch((error) => {
       console.error(error.response);

       this.$emit("activeBanner");
       this.$emit("success", false);
       this.$emit("successMsg", error.response.data.message);
      });
    }
   }
  },
 },
 mounted() {
  this.getData(this.token);
 },
 computed: {},
 watch: {},
};
</script>

<style></style>
