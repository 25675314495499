<template>
 <div class="bg-white">
  <div class="max-w-7xl mx-auto px-4 pt-4 pb-10 sm:px-6 lg:px-8">
   <div class="justify-center text-center">
    <img
     src="https://storage.gra.cloud.ovh.net/v1/AUTH_9c30d35f284f44b2bda08609e7c19f33/bluerocktelclients/telecom0525/logo-horizontal.jpg"
     alt=""
     class="h-28 w-34 m-auto"
    />
    <h2 class="text-base font-semibold primary_text tracking-wide uppercase">
     {{ $t(title) }}
    </h2>
    <p class="mt-1 text-3xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-4xl">
     {{ $t(header) }}.
    </p>
    <p class="max-w-xl mt-5 mx-auto text-base sm:text-xl text-gray-500">
     <font-awesome-icon
      icon="hands-clapping"
      class="h-5 w-5 sm:h-6 sm:w-6 primary_text mr-2 fa-beat"
      style="--fa-beat-scale: 1.1;--fa-animation-duration: 2s;"
     />
     {{ $t(headerDescription) }}.
    </p>
   </div>
  </div>
 </div>
</template>
<script>
export default {
 name: "HeaderSection",
 props: {
  title: String,
  header: String,
  headerDescription: String,
 },
 components: {},

 data() {
  return {};
 },
 methods: {},
 mounted() {},
 computed: {},
 watch: {},
};
</script>

<style></style>
