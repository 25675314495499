export default {
  "associateNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro(s) associé(s)"])},
  "adminExt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin extension"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "wishTocontinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous continuer ?"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
  "openingDaysAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours et horaires d'ouverture"])},
  "strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stratégie heures d’ouverture et de fermeture"])},
  "predecroche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prédécroché"])},
  "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réouvrir le ticket"])},
  "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le ticket"])},
  "customerInformationCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collecte d'informations"])},
  "homePageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infomations nécéssaires pour la préparation de votre contrat Encom"])},
  "homePageHeaderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous remercions de votre confiance"])},
  "predecrocheAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predecroche agent"])},
  "destNoResp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination en cas de non-réponses"])},
  "adminNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin notifications"])},
  "isComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is complete"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marketing"])},
  "campaignReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign ready"])},
  "campaignInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign information"])},
  "campaignDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign detail and statistics"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété"])},
  "toBeCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à compléter"])},
  "wrongRioFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le RIO n'est pas au bon format"])},
  "provideCorrectNumberOrRio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir le bon numéro de téléphone et/ou le bon RIO associé"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnalisée"])},
  "cantBeEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doit être complété"])},
  "expeditorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'expéditeur"])},
  "expeditorNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom affiché pour le destinataire lors de la réception du SMS"])},
  "newOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle organisation"])},
  "newOrganisationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer une nouvelle organisation en remplissant le formulaire ci-dessous"])},
  "smsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de SMS"])},
  "callQueueOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre de distribution"])},
  "callQueueOrderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez les flèches ou faites glisser pour sélectionner l'ordre de distribution dans la file d'attente"])},
  "disactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactiver"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "deleteMessageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cet élément"])},
  "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous supprimez cet élément, vous risquez de perdre les informations des formulaires déjà remplis. Souhaitez-vous continuer ?"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher moins"])},
  "ownedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détenu par"])},
  "ownerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email du propriétaire"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
  "otherInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres informations"])},
  "otherInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter ce destinataire à une liste"])},
  "organisationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de l'organisation"])},
  "organisationInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation à laquelle vous êtes lié et les détails à ce sujet"])},
  "smsTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de SMS"])},
  "smsMessageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le contenu du SMS"])},
  "marketingDisclaimerPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention ! Pour tous les SMS marketing, le message doit finir par"])},
  "marketingDisclaimerPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est obligatoire afin de fournir à vos destinataires un moyen de se désabonner et de respecter les obligations de la CNIL."])},
  "campaignNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le nom de votre campagne"])},
  "recipientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le destinataire"])},
  "recipientInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez les informations du nouveau destinataire ici"])},
  "recipientListInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez les informations de la nouvelle liste ici"])},
  "recipientInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez ici les destinataires de votre SMS. Vous pouvez également combiner cette fonction avec une liste de destinataires"])},
  "signinToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "newPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre."])},
  "updateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'utilisateur"])},
  "updateOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'organisation"])},
  "updateUserDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez les nouvelles informations ci-dessous"])},
  "updateCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la campagne de SMS"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facturation"])},
  "billingMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen de facturation"])},
  "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campagne"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenu"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prêt"])},
  "newCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle campagne"])},
  "newRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau destinataire"])},
  "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau compte"])},
  "newAccountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer un nouveau compte en remplissant le formulaire ci-dessous"])},
  "newList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle liste"])},
  "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
  "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campagnes"])},
  "recipientsLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes de destinataires"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinataire"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liste"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateur"])},
  "accountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le compte pour cette campagne"])},
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comptes"])},
  "goodMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonjour"])},
  "goodAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonne journée"])},
  "goodEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonne soirée"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepté"])},
  "notAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à accéder à ce contenu."])},
  "addGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter de nouveaux groupes"])},
  "addGroupsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore ajouté de groupe d'extensions à votre collecte. Vous pouvez commencer maintenant et gérer leurs permissions"])},
  "autoCallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel automatique"])},
  "autoCallbackDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez comment proposer le rappel automatique"])},
  "autoCallbackDescriptionYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nécessite un prédécroché informant le client qu'il peut demandé un callback en tapant la touche 2 sur son clavier à tout moment lors de l'attente"])},
  "autoCallbackDescriptionNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option désactivée"])},
  "autoCallbackTypeDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquer la tempo en secondes. La durée de la tempo inclut le prédécroché, par exemple si le prédécroché dure 30 sec et que la tempo de rappel auto est à 45 sec, le client aura l'impression qu'on lui propose le rappel auto seulement apres 15 sec d'attente. Vous pouvez nous fournir un message studio pour l'annonce du rappel Auto ou utiliser le message par défaut 3CX"])},
  "associatedQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File d'attente(s) associée(s) au message"])},
  "associatedExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension(s) associée(s) au message"])},
  "associatedNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro(s) associé(s) au message"])},
  "associatedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["associé(s) au message"])},
  "notRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction « Touche 3 = ne pas être enregistré »"])},
  "notRecordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous enregistrez les appels entants, vous pouvez proposer aux appellants de ne pas enregistrer l'appel"])},
  "notRecordYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message de prédécroché doit préciser que l'appelant a la possibilité de ne pas être enregistré en composant la touche 3 de son téléphone"])},
  "notRecordNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message de prédécroché doit préciser que l'appelant a la possibilité de demander à son interlocuteur de ne pas enregistrer la conversation"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importer"])},
  "importFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier"])},
  "importUserWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’importation d’un fichier supprime et remplace tous les utilisateurs créés auparavant"])},
  "importFileDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez la possibilité d'importer un fichier contenant l'intégralité de votre collecte utilisateurs"])},
  "importFileDetail2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le format du fichier à compléter puis importer"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées bancaires"])},
  "bankDetailsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignez vos informations bancaires"])},
  "doItLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter plus tard"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
  "attentionNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention requise"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glisser-déposer"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message d'accueil"])},
  "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer par"])},
  "uploadedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer le"])},
  "uploadAFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aucun"])},
  "adminQueueExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur(s) de la file d’appels"])},
  "userQueueExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de la file d’appels"])},
  "strategyOpeningHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stratégie heures d’ouverture"])},
  "strategyClosingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stratégie heures de fermeture"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse(s)"])},
  "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres avancés"])},
  "nonWorkingTimeDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination aux heures de fermetures"])},
  "workingTimeDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination aux heures d'ouverture"])},
  "nonAnsweringDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination en cas de non-réponses"])},
  "audioMessageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message d’accueil du SVI"])},
  "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activé"])},
  "prehook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-décroché"])},
  "announcementMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message d’annonce"])},
  "prehookMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message de pré-décroché"])},
  "prehookMessageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tout moment lors de l’attente, l’appelant active le rappel en tapant la touche 2. Cette option nécessite la mise en place d’un message de pré-decroché (voir ci-dessus Stratégie heure d’ouverture) qui informe l’appelant de cette possibilité"])},
  "delayTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delai d'attente"])},
  "delayTimeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rappel est proposé automatiquement après un délai d’attente que vous spécifiez en secondes"])},
  "messageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du message"])},
  "prehookDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous dans la section 06 Studio pour créer ou importer votre pré-décroché"])},
  "prehookBeforeCallAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand souhaitez-vous que l’extension de l’agent commence à sonner ?"])},
  "strategyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stratégie de distribution des appels"])},
  "strategyTypePopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la stratégie de distribution des appels aux agents puis indiquez à droite le délai en secondes durant lequel un agent sonne avant que l'appel ne soit basculé sur l'agent suivant (1 sonnerie correspond à 5 secondes)"])},
  "switchingTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délais de bascule en secondes"])},
  "activateRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer l'enregistrement"])},
  "activateRecordingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les appels externes (entrants et sortants) sont automatiquement enregistrés"])},
  "desactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé"])},
  "desactivateRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver l’enregistrement"])},
  "desactivateRecordingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun appel n'est enregistré"])},
  "afterMessagePredecroche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’extension de l’agent commence à sonner une fois que le message de prédécroché est terminé"])},
  "withMessagePredecroche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’extension de l’agent commence à sonner dès le début de lecture du message de prédécroché"])},
  "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel"])},
  "manualRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer l’enregistrement manuel"])},
  "manualRecordingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’enregistrement des appels est déclenché manuellement"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
  "addressDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une ou plusieurs adresses géographiques"])},
  "availableFromVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à partir de la version"])},
  "contactDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez au moins 1 contact pour chaque rôle (un contact peut avoir plusieurs rôles)"])},
  "contactDetail1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire du contrat"])},
  "contactDetail2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire mandat SEPA"])},
  "contactDetail3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact collecte"])},
  "contactDetail4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact technique"])},
  "contactDetail5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact facturation"])},
  "contactDetailList1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact qui signera le bon de commande Encom"])},
  "contactDetailList2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrat qui signera le mandat SEPA pour le prélèvement automatique"])},
  "contactDetailList3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact qui sera charger de compléter la collecte technique pour paramétrer 3"])},
  "contactDetailList4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact en charge du déploiement"])},
  "contactDetailList5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact à qui sera envoyé les factures Encom"])},
  "signatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choix"])},
  "musicChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix de la musique"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["musique"])},
  "withMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec musique"])},
  "withoutMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans musique"])},
  "voiceChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix de la voix"])},
  "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voix"])},
  "typeYourMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir ici le texte de votre message"])},
  "useMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utiliser ce message"])},
  "phonetic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précisions phonétiques des mots sujets à interprétation"])},
  "calls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels"])},
  "newQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Groupe"])},
  "callQueueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la file"])},
  "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement des appels"])},
  "recordingDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’enregistrement des appels est activé sous réserve de conformité au RGPD. Un prédécroché du type"])},
  "isMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est obligatoire"])},
  "recordingDisclaimerSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre conversation est susceptible d'être écoutée ou enregistrée"])},
  "recordingDisclaimerList1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquez la durée de stockage des messages dans la section commentaire"])},
  "recordingDisclaimerList2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La souscription à une option de stockage supérieur à 1 mois fait l'objet d'une option payante et doit figurer sur le bon de commande"])},
  "callsRestrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction sur autres destinations activée : indiquez dans la section commentaire les autres restrictions pour chaque utilisateur ou groupe d'utilisateur"])},
  "noCallsRestrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction sur autres destinations desactivée"])},
  "signatoryContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire"])},
  "legalRepresentative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentant légal"])},
  "isSignerContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire du contrat"])},
  "collectContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact collecte"])},
  "isSignerIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire mandat SEPA"])},
  "ivsDescriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple : Pour être mis en relation avec le service commercial, composez le 1, pour être mis en relation avec le service technique composer le 2, etc."])},
  "ivsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction SVI (Serveur Vocal Interactif) de 3CX permet de répondre à des appels téléphoniques automatiquement et de proposer un menu d’options aux appelants. Par exemple, “Pour le service commercial tapez 1, pour le support tapez 2 ou patientez pour être transféré à un opérateur”"])},
  "ivsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de SVI"])},
  "ivsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du SVI"])},
  "messageNameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de file d'attente est déjà utilisé"])},
  "technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technique"])},
  "technicalContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact technique"])},
  "accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptable"])},
  "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue"])},
  "errorOnFinalFormIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez founir un IBAN valide"])},
  "errorOnFinalFormBIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez founir un SWIFT/BIC valide"])},
  "requestSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications effectuées avec succès"])},
  "accountingContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact facturation"])},
  "invoicesRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact facturation"])},
  "physicalSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites physiques"])},
  "physicalSiteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du site physiques"])},
  "trainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations 3CX prise en charge par votre OPCO"])},
  "trainingMandateHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous bénéficiez d’une formation 3CX prise en charge par votre OPCO, veuillez indiquer si l’utilisateur participera à une formation Utilisateur et/ou Administrateur"])},
  "trainingUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation Utilisateur"])},
  "trainingAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation Admin"])},
  "thereWere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a eu"])},
  "thereIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a"])},
  "oneOrMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["une ou plusieurs"])},
  "errorWithYourSubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur(s) avec votre demande"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse e-mail ou mot de passe invalide"])},
  "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreurs"])},
  "needAtLeastOneContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nécessite d'au moins un contact"])},
  "needAtLeastOneAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nécessite d'au moins une adresse"])},
  "miscellaneous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divers"])},
  "webClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Web"])},
  "webClientDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet à l'utilisateur de se connecter à l'interface de gestion 3CX via une page web"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "somethingWrongHappened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page que vous cherchez est introuvable"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "resumeInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé des informations"])},
  "resumeInformationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails des informations envoyées précédemment"])},
  "finishedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé le"])},
  "contactsLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de contacts"])},
  "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prélèvement bancaire"])},
  "requestIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
  "thanksForValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre validation"])},
  "informationWasUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces informations ont été mises à jour le"])},
  "closeCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la collecte"])},
  "closeCollectPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider ces informations"])},
  "closeCollectPopupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois ces informations validées vous ne pourrez plus les modifier."])},
  "closeCollectPopupInfoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En fermant ce formulaire, vous acceptez les détails que vous avez fournis auparavant et ces informations seront envoyées pour créer la Collecte."])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
  "thankYouTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations envoyées"])},
  "thankYouHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec succès"])},
  "thankYouParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir rempli cette collecte d'informations"])},
  "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prochaine étape"])},
  "nextStepParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous allez recevoir un e-mail avec votre contrat à signer via DocuSign"])},
  "reportIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous pensez avoir fait une erreur, veuillez nous le faire parvenir en nous envoyant un email à"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "openTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours et horaires d'ouverture"])},
  "openTimeSlotDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour ajouter les jours et heures d'ouverture"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
  "intercallDelay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de pause entre la réception de 2 appels"])},
  "custom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnalisé"])},
  "noCustom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non personnalisé (2 secondes)"])},
  "intercallDelayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisez le temps de pause (secondes) entre la réception de 2 appels"])},
  "priorityQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File prioritaire"])},
  "priorityQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cas ou des agents appartiennent à plusieurs files, les appels de la file prioritaire seront distribués en priorité aux agents"])},
  "voicemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages vocaux non envoyés/envoyés"])},
  "voiceMailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîte vocale"])},
  "voicemailBeNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages vocaux envoyés"])},
  "voicemailNotToBeNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages vocaux non envoyés"])},
  "missedCallsBeNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels manqués notifiés"])},
  "missedCallsNotToBeNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels manqués non notifiés"])},
  "voicemailDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification par email avec fichier audio en pièce jointe"])},
  "restrictionsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décidez quelles sont les destinations que l’utilisateur ne peut pas appeler"])},
  "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
  "customerProvideSwitchYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, nous fournirons le switch."])},
  "customerProvideSwitchNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, le switch sera fourni par Encom."])},
  "firewallProviderYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, nous sous-traitons la maintenance à un prestataire externe."])},
  "firewallProviderNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, la gestion du Firewall est gérée en interne."])},
  "smartphoneWifiYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, dans nos locaux nous utiliserons principalement nos smartphones pour nous connecter à 3CX."])},
  "smartphoneWifiNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, dans nos locaux nous utiliserons principalement nos téléphones IP et/ou nos ordinateurs pour nous connecter à 3CX."])},
  "hotdesking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet à l'usager de se connecter à un poste physique dédié au"])},
  "hotdeskingDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un poste Hot Desking est un poste qui est attribué à aucun utilisateur fixe et qui permet à plusieurs utilisateurs nomades de s'y connecter à l'aide d'un code pin"])},
  "invoicesDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce contact doit recevoir les factures mensuelles par email"])},
  "contactAdminDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce contact est administrateur de l’Espace Client Encom"])},
  "ipPhoneModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associer un poste téléphonique à l’utilisateur"])},
  "pleaseUseFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format doit être du type"])},
  "errorOnFinalFormFqdn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir votre domaine"])},
  "errorFormNumberInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette sélection crée un conflit avec une sélection existante."])},
  "errorFormNumberFromSmallerThanTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le début de la fourchette ne peut pas être plus petit à la fin."])},
  "errorFormNumberFromAndToEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez ajouter un seul numéro, utilisez plutôt la section numéros isolés."])},
  "emailErrorFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format e-mail doit être du type"])},
  "emailErrorAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse électronique a déjà été utilisée"])},
  "followFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez respecter le format"])},
  "errorIsInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un nombre dans une fourchette donnée"])},
  "numbAlreadyAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro a déjà été ajouté."])},
  "errorCreatedExtensionsLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette extension doit contenir autant de chiffres que ceux enregistrés précédemment."])},
  "forbiddenExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à utiliser cette extension."])},
  "tooltipOPCO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un OPCO est un organisme agréé par l'Etat chargé de financer vos formations. Vous devez obligatoirement sélectionner votre OPCO si votre formation Encom est prise en charge par votre OPCO."])},
  "theEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email"])},
  "notificationMandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décidez quelles sont les notifications que recevra par email l’utilisateur"])},
  "notificationMandate2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut, aucunes notifications n'est activées"])},
  "yesPredecrocheAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'extension commencera à sonner uniquement lorsque le message de pré-décroché est terminé"])},
  "noPredecrocheAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'extension commencera à sonner dès le début de lecture du message de pré-décroché"])},
  "predecrocheMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musique/Message d'attente"])},
  "goToSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous dans la section"])},
  "toManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour gérer vos"])},
  "predecrocheMusicDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous dans la section 06 Studio pour créer ou importer votre pré-décroché"])},
  "predecrocheMusicDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La musique d’attente de 3CX sera jouée par défaut"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oui"])},
  "maxCallerNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre maximum d'appelant dans la file d'attente"])},
  "allDestinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les destinations ouvertes"])},
  "noResponseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier le délai d’attente de l’appel avant d’être transféré en cas de non réponse (en secondes)"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "outputDisplayNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro à presenter en sortie"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone fixe"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone fixe"])},
  "extensionMandateDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formats de 3 à 5 chiffres (sauf 112 réservé aux urgences)"])},
  "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'utilisateur"])},
  "personalInformationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations relatives à l'utilisateur"])},
  "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'élément"])},
  "editItemMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous modifiez cet élément, vous risquez de perdre des informations provenant de formulaires déjà remplis. Souhaitez-vous continuer ?"])},
  "editItemMessageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez conserver les informations déjà existantes, cliquez sur annuler."])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajouter"])},
  "unsavedChangesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées"])},
  "unsavedChangesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cliquant sur « Quitter sans enregistrer » ces modifications seront perdues."])},
  "unsavedChangesMessageOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer sur  « Annuler » pour revenir à la Collecte et enregistrer vos nouvelles modifications."])},
  "unsavedChangesCancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter sans enregistrer"])},
  "unsavedChangesOkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications"])},
  "internationalCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels internationaux"])},
  "internationalCallDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez les destinations pour lesquelles les appels internationaux sont autorisés"])},
  "internationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les destinations autorisées"])},
  "restrictionsInternationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions sur appels internationaux"])},
  "restrictionsMissCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions sur autres destinations"])},
  "internationalDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier les destinations à ouvrir"])},
  "restrictionsActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction activée : aucun appel international ne pourra être émis"])},
  "restrictionsDesactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction désactivée : les appels internationaux peuvent être émis dans la limite des destinations ouvertes dans la Section 01"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
  "numberIsolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros isolés (Ligne analogique, Fax, Numéro VoIP)"])},
  "bannerSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes en mode Sandbox"])},
  "bannerProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, vous êtes en mode production !"])},
  "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "passwordResetMustContain12Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins 12 caractères"])},
  "passwordResetMustContainOneUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins une majuscule"])},
  "passwordResetMustContainOneSpecialCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins un spécial caractère"])},
  "passwordResetMustContainOneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins un nombre"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom"])},
  "sda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros"])},
  "portabilityMandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandat de portabilité"])},
  "portabilityMandateDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1) Téléchargez ici le mandat de portabilité à compléter (2) Vérifiez et signez votre mandat (3) Importez votre mandat"])},
  "portabilityMandateAndInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandat(s) de portabilité et facture(s)"])},
  "portabilityMandateDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mandat doit être aux mêmes raison sociale et adresse que la facture de votre ancien opérateur"])},
  "portabilityMandateDetail2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mandat doit être aux mêmes raison sociale et adresse que la facture de votre ancien opérateur"])},
  "telephoneOperatorInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture opérateur téléphonique"])},
  "telephoneOperatorInvoiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez importer une copie de vos factures opérateurs (moins de 3 mois) sur lesquelles figurent les numéros à porter et les numéros de tête de ligne"])},
  "sdaCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de numéros"])},
  "sdaCreationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez créer de nouveaux numéros, veuillez indiquer le nombre de numéros à créer et la ville où ils seront utilisés"])},
  "sdaCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de numéros à créer"])},
  "sdaCreateDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par tranche de 5 numéros minimum"])},
  "sdaLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro(s) associé(s)"])},
  "sviSdaLinkedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir un ou plusieurs numéro(s) à associer"])},
  "numberToAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro(s) à attribuer à l’utilisateur"])},
  "sdaOrderedLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro(s) restants"])},
  "sdaOrderedLeftDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez votre numéro demandés en portabilité ou attribuez un nouveau numéro que vous avez commandé"])},
  "newNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer un nouveau numéro"])},
  "associatedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer à"])},
  "carriedNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un de vos numéros portés"])},
  "ownNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un numéro externe"])},
  "internalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension à associer à l’utilisateur"])},
  "listsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de liste"])},
  "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File d'appel"])},
  "callqueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files d'appels"])},
  "waitingQueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files d'attentes"])},
  "waitingQueuesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La file d’appels permet de rediriger un appel entrant vers plusieurs agents. Lorsque tous les agents sont occupés, les appels sont mis en attente jusqu’à ce que l’un d’eux se libère"])},
  "waitingQueuesDescriptionOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les files d'appels permettent de mettre en attente les appels le temps que les agents (membres d’une file d’attente) puissent répondre aux appels. Les appels ne restent pas sans réponse, ils sont mis en attente. Par exemple, il est possible de mettre en place un groupe de 3 commerciaux, et de faire en sorte que le numéro du service commercial soit routé vers une file d’attente spécifique au département commercial. Si les 3 commerciaux sont occupés, les correspondants seront mis en file d’attente jusqu’à ce qu’un commercial se libère. Ce tableau permet de définir le fonctionnement d'une ou plusieurs files d'appel"])},
  "queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File d'appel"])},
  "callFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call flow"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monde"])},
  "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout supprimer"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "callQueueNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de file d'appel"])},
  "confirmCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer et envoyer"])},
  "missedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels manqués non notifiés/notifiés"])},
  "missedCallsDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils sont envoyés par email a l'adresse renseigne plus haut"])},
  "numberOfCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels"])},
  "beNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite être notifié par e-mail"])},
  "notBeNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne souhaite pas être notifié par e-mail"])},
  "answeredCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appels répondu"])},
  "numberOfAnsweredCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels répondu"])},
  "mainInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information générales"])},
  "numbersPortability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros & Portabilité"])},
  "numbersPortabilityTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portabilité de vos numéros"])},
  "numbersPortabilityDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portabilité des numéros vous permet de conserver vos numéros actuels. Veuillez indiquer les numéros que vous souhaitez porter"])},
  "rangeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche de numéros à porter"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateurs"])},
  "usersMandateHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisation des extensions 12, 15, 17, 18 et 112 sont reservées aux numéros d'urgence"])},
  "usersCollectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette section permet de définir pour chaque utilisateur les extensions et les droits qui lui sont associés"])},
  "siteAndNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites & Réseaux"])},
  "customerRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations Clients"])},
  "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions Légales"])},
  "EndCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conclusion"])},
  "legalNoticeDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir pris le temps de remplir ce document"])},
  "privacyGdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RGPD"])},
  "privacyGdprDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est obligatoire de valider la connaissance des règlements mis en place par le RGPD"])},
  "privacyGdprToogleLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cochant ci-dessous, vous confirmez que vous avez pris connaissance de la réglementation RGPD"])},
  "privacyGdprComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour toute information complémentaire sur votre configuration, vous pouvez remplir ce champs"])},
  "goBackToDependentStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez d'abord compléter l'étape"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de l'extension"])},
  "generalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres généraux"])},
  "globalSettingsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales concernant votre 3CX"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du site"])},
  "youUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez importez"])},
  "youCanAddByClicking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez les ajouter en cliquant sur le boutton"])},
  "successfullyUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importation réussi avec succès"])},
  "below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus bas"])},
  "3cxDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine 3CX"])},
  "usersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette section permet d’ajouter les utilisateurs dans le groupe puis de définir pour chaque utilisateur les extensions et les droits qui lui sont associés"])},
  "yourDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre domaine"])},
  "3cxDomainType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse URL de connexion à votre domaine 3CX"])},
  "3cxDomainRoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine du domaine 3CX"])},
  "opco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre OPCO"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° et libellé de la voie"])},
  "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "pleaseCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez corriger"])},
  "callsAnsweredByAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["répondu par autres"])},
  "numberOfCallsAnsweredByAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels répondu par quelqu'un d'autres"])},
  "callsTransferredByAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transférés par un agent"])},
  "numberOfCallsTransferredByAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels transférés par un agent"])},
  "abandonedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appels abandonnés"])},
  "numberOfAbandonedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels abandonnés"])},
  "callsDeterred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appels dissuadés"])},
  "numberOfCallsDeterred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels dissuadés"])},
  "averageWaitingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente moyen"])},
  "averageWaitingTimeQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente moyen (file d'appels)"])},
  "dropoutTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps moyen de décrochage"])},
  "averageDropoutTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps moyen d'abandon de l'utilisateur durant l'attente"])},
  "averageCallDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moyen"])},
  "queueDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail file d'appels"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "solicited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solicités"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro"])},
  "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéros"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fermer"])},
  "didCallers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels SDA"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
  "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
  "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'utilisateur"])},
  "deleteStudioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le message studio"])},
  "deleteSvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le SVI"])},
  "groupDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe supprimé avec succès"])},
  "userDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur supprimé avec succès"])},
  "sviDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SVI supprimé avec succès"])},
  "studioMessageDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message studio supprimé avec succès"])},
  "groupsQueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de file d'appels"])},
  "groupsExtensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes d'extensions"])},
  "queueComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de file d'appels"])},
  "numberOfRepeatedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels répétés"])},
  "answeredCallsCoefficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coefficient d'appels répondu"])},
  "abandonedCallsCoefficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coefficient d'appels abandonnés"])},
  "dissuadedCallsCoefficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coefficient d'appels dissuadés"])},
  "comparisonSamePeriodLastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer à la période précédente"])},
  "noCallersForThisPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun appel trouvé pour cette période"])},
  "answered_00_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités en moins de 30 secondes"])},
  "answered_31_60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités entre 30 et 60 secondes"])},
  "answered_61_90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités entre 60 et 90 secondes"])},
  "answered_90+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités après 90 secondes"])},
  "averageAnswered_00_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités en moins de 30 secondes"])},
  "averageAnswered_31_60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités entre 30 et 60 secondes"])},
  "averageAnswered_61_90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités entre 60 et 90 secondes"])},
  "averageAnswered_90+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels traités après 90 secondes"])},
  "qs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score qualité"])},
  "transfered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transféré"])},
  "compareTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec"])},
  "pleaseSelectTwoQueuesForComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner deux files d'attente"])},
  "comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comparaison"])},
  "queueHasNoData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La file d'appels sélectionnée n'a pas de données pour cette période"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programmé"])},
  "scheduleReportEditDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous configurer un rapport planifié ?"])},
  "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crée le"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "pageNotfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non trouvée"])},
  "unauthorizedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes connecté avec un utilisateur non autorisé."])},
  "mandateIdentification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant Mandat"])},
  "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
  "reportDetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur le rapport"])},
  "reportEditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information générale"])},
  "reportDetailTitleDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail global et paramètre du rapport"])},
  "reportEditTitleDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration générale du rapport"])},
  "pbxHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hôte PBX"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "emailAddressReportEditDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les personnes qui reçoivent le rapport"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aperçu"])},
  "previewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez prévisualisation la présentation en cliquant sur le bouton ci-dessous"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalier"])},
  "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
  "cronDaily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rapport sera partagé tous les jours, à 04:00."])},
  "cronWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rapport sera partagé tous les Lundis, à 04:00."])},
  "cronMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rapport sera partagé tous les 1er du mois, à 04:00."])},
  "noReportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rapport trouvée"])},
  "noReportDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau rapport en allant dans les tableaux de bord"])},
  "noHostName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has no hosts"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
  "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verouillé"])},
  "collectionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Collections"])},
  "filesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des dossiers"])},
  "lostChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles modifications perdues"])},
  "informationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sauvegardées"])},
  "extensionAlreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette extension est déjà utilisée."])},
  "errorImportUsers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il y a eu ", _interpolate(_named("number")), " erreur(s) avec votre demande."])},
  "errorMissingImportFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier RIB manquant"])},
  "errorMissingBankDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées bancaires manquantes"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
  "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non du dossier"])},
  "fileInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de dossier"])},
  "fileInformationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du dossier client et des collectes"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "studioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages studio"])},
  "studioMessageExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemples de messages de pré-décroché"])},
  "studioMessageCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to add a new message?"])},
  "CRMIntegrationQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous intégrer votre CRM/ERP?"])},
  "CRMIntegrationActivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les intégration"])},
  "CRMIntegrationDesactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver les intégration"])},
  "expertServices3cxActivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les Services Experts 3CX"])},
  "expertServices3cxDesactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver les Services Experts 3CX"])},
  "smsActivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les Services SMS"])},
  "smsDesactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver les Services SMS"])},
  "CustomerRelationModules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations 3CX"])},
  "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du client"])},
  "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° client"])},
  "CustomerRelationFormInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec 3CX vous pouvez bénéficier de l’intégration CRM, ERP ou système de comptabilité avec votre IPBX pour pouvoir passer des appels vers vos contacts d’un simple clic depuis vos applications. De plus, les appels entrants sont automatiquement liés à un compte client qui s’ouvre sur votre écran et tous les appels sont loggés dans votre CRM."])},
  "SmartRouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routage intelligent"])},
  "CustomerSatisfacionSurvey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquete satisfaction client"])},
  "VoiceRecognition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnaissance vocale"])},
  "LiveWebChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web chat live"])},
  "WebCallBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web call back"])},
  "PushSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push SMS"])},
  "SelectModules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select modules"])},
  "warningTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])}
  },
  "expertStatisticsNavigation": {
    "advancedStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques détaillées"])},
    "origins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["origines"])},
    "originsTop10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origines Top 10"])},
    "hourlyStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques horaire"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "answeredUnansweredCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["répondus & non répondus"])},
    "reportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rapport"])}
  },
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous n'avons pas trouvé la page que vous recherchez..."])},
  "goBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'acceuil"])},
  "sidebar": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableaux de Bord"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "tickets": {
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverts"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermés"])}
    },
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "subMenu": {
      "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances"])},
      "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File d'appels"])}
    },
    "specialNavigation": {
      "expertService3CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Experts 3CX"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routages Intelligents"])},
      "georouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routages Géographiques"])},
      "callqueuegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de files d'appels"])},
      "callqueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files d'appels"])},
      "reportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rapport"])}
    }
  },
  "header": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchTicketPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujets, dates de création ou dates de mise à jour"])},
    "searchInvoicePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures, dates ou totaux"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Actif"])},
    "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Compte"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Imitation"])},
    "leaveImpersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter Mode Imitation"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Ticket"])},
    "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre profil"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par statut"])}
  },
  "notificationBanner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre application a de nouvelles fonctionnalités ! Venez en découvrir plus."])},
    "learnMoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])}
  },
  "impersonate": {
    "popupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner l'adresse e-mail d'un Utilisateur pour passer en mode Imitation"])}
  },
  "chartTable": {
    "callType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
    "avgPerCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moyenne"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne fixe"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spéciale"])},
    "callCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels"])},
    "callDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels"])},
    "fileNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de document"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "choosePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la période"])},
    "chooseResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la ressource"])},
    "resourceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de ressource"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphiques"])},
    "data": {
      "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrant"])},
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortant"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondu"])},
      "unanswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Répondu"])}
    }
  },
  "dashboards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
    "fullDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journée entière"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "concatenate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consolider les données"])},
    "concatenateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données consolidées pour les éléments"])},
    "chartHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques pour la période"])},
    "searchExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher extensions"])},
    "searchQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher file d'appels"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisée"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaire"])},
    "hourlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord par heure de "])},
    "hourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données horaires de"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalier"])},
    "dailyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord Journalier de"])},
    "dailyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données journalières de"])},
    "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
    "monthlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord Mensuel de"])},
    "monthlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données mensuelles de"])},
    "donutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels quotidien répondu et non répondu"])},
    "stackedColumnCategories": {
      "incomingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels entrant"])},
      "outgoingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels sortant"])},
      "answeredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels répondu"])},
      "unansweredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels non répondu"])},
      "incomingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Rentrant"])},
      "outgoingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Sortant"])},
      "answeredDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Répondu"])}
    },
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout désélectionner"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réinitialiser"])},
    "error": {
      "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
      "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données pour cette période n'ont pas été trouvées ou n'existent pas encore, nous sommes désolés pour ce désagrément."])}
    }
  },
  "warning": {
    "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
    "noResultFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun resultat trouvé"])},
    "noResultFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez d'affiner votre recherche ou filtrer autrement."])},
    "noDataFoundDocumentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Documents"])},
    "noDataFoundFilesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Files"])},
    "noDataFoundDocumentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez d'aucun document. Contactez votre supérieur hiérarchique si cela n'est pas censé se produire."])},
    "noDataFoundFilesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez d'aucun file. Contactez votre supérieur hiérarchique si cela n'est pas censé se produire."])},
    "noDataFoundContactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Contacts"])},
    "noDataFoundContactDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau contact ou importez une liste de contact."])},
    "noDataFoundCtiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun CTI"])},
    "noDataFoundCtiDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer une nouvelle configuration CTI."])},
    "noDataFoundRoutingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Routages Géographiques"])},
    "noDataFoundRoutingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau routages géographiques."])},
    "noDataFoundCallQueueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune file d'appels"])},
    "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer une nouvelle file d'appels."])},
    "noDataFoundCallQueueGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe de file d'appels"])},
    "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau groupe de file d'appels."])},
    "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun créneau horaire n'a encore été créé. Veuillez en ajouter un en cliquant sur le bouton 'Nouvelle Plage Horaire'."])},
    "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun jours exceptionnels n'a encore été créé. Veuillez en ajouter un en cliquant sur l'un des boutons 'Nouveau Jour Exceptionnel'."])},
    "noDataFoundSMSListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune listes de SMS"])},
    "noDataFoundSMSListDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer une nouvelle liste de SMS."])},
    "noDataFoundDashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun tableau de bord"])},
    "noDataFoundElementGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau groupe."])},
    "noDataFoundDashboardDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par sélectionner des éléments et une période de temps."])},
    "mandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si votre numéro est lié à un abonnement internet, la portabilité du numéro entrainera la résiliation automatique de cet abonnement internet et de la ligne support analogique associée. Il est donc important de vérifier qu'aucun service ne dépende de cette ligne analogique : terminal de paiement, alarme, fax, etc"])}
  },
  "pbxExtension": {
    "selectData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une données"])},
    "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une période"])}
  },
  "home": {
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])}
  },
  "ticket": {
    "ticketForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de création de ticket"])},
    "typeDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner votre question/problème ci-dessous"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-catégorie"])},
    "selectProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le problème"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "emailSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet du ticket"])},
    "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolution estimée"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "typeAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédiger votre problème ci-dessus"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
    "writeReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez votre réponse pour le ticket"])},
    "concerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concerns"])}
  },
  "invoiceTable": {
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturer ID"])},
    "invoiceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
    "totalWithoutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant HT"])},
    "totalWithTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant TTC"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "searchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche ID"])},
    "searchNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche numéro"])},
    "searchMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "searchYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AAAA"])},
    "searchAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche montant"])}
  },
  "ticketTable": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réouvrir"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner des lignes"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lignes"])},
    "confirmationCloseMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir clôturer le ticket?"])},
    "confirmationOpenMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir réouvrir le ticket?"])},
    "closeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket fermé avec succès."])},
    "openMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ouvert avec succès."])},
    "reopenMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket réouvert avec succès."])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
    "dialogTitleClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturer le ticket"])},
    "dialogTitleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réouvrir le ticket"])},
    "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage de"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
    "searchStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche status"])},
    "searchDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JJ/MM/AAAA"])},
    "ticketList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tickets"])}
  },
  "documentTable": {
    "allDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les documents"])},
    "yourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos documents"])}
  },
  "callqueue": {
    "buttonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle file d'appels"])},
    "buttonTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau groupe de files d'appels"])},
    "newFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File d'appels de routages horaires"])},
    "newFormTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de file d'appels"])},
    "updateException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le jour exceptionnel"])},
    "updateTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le créneau horaire"])},
    "updateFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la file d'appel"])},
    "updateFormTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe de file d'appels"])},
    "formSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les informations de la nouvelle file d'appel ci-dessous."])},
    "formSubTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les informations du nouveau groupe de file d'appels ci-dessous."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour en complétant les informations ci-dessous"])}
  },
  "callqueueTable": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de file"])},
    "weekdayAndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour de la semaine ou date"])},
    "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour de la semaine"])},
    "specificDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date précise"])},
    "timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plages horaires"])},
    "exception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours exceptionnels"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["référence"])},
    "addTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle plage horaire"])},
    "addException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau jour exceptionnel"])},
    "addExceptionTwelveMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les jours fériés des 12 prochains mois"])},
    "formTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un jour ou une plage de jours suivis des créneaux horaires associés"])},
    "formExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter une exception à la liste d'appels en sélectionnant un jour spécifique"])},
    "formExceptionTwelveMonthsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici les douze prochains jours fériés pour la France"])},
    "selectAllDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les jours de la semaine"])},
    "selectAllWorkingDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les jours du Lundi au Vendredi"])},
    "inputSelectDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner plusieurs jours en utilisant"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "dateSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date sélectionnée"])},
    "daySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour(s) sélectionné(s)"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
    "checkStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le début ne peut pas se situer dans une plage horaire existante"])},
    "checkEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fin ne peut pas se situer dans une plage horaire existante"])},
    "checkBetween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette plage horaire est en conflit avec un créneau existant"])},
    "pickedStartBigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le début ne peut pas être après ou identique à la fin"])},
    "newExceptionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau jour exceptionnel choisie"])},
    "newExceptionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, il y a une erreur dans la date choisie"])},
    "newExceptionExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce jour exceptionnel existe déjà"])},
    "selectExceptionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un jour dans le calendrier avant de continuer"])},
    "destinationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez préciser une destination ici. L'API retourne une information relative à l'ouverture ou à la fermeture de la file d'appels en fonction de l'horaire. Ne précisez pas de destination ici si votre call flow appelle l'API ultérieurement pour router l'appel (routage géographique ou intelligent...)"])},
    "nextFrenchHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours fériés français sur les 12 prochains mois"])},
    "addFrenchHolidaysWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ajouterez automatiquement des exceptions pour toutes les dates spécifiées ci-dessus"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous continuer ?"])}
  },
  "weekdays": {
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lundi"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mardi"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mercredi"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jeudi"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vendredi"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samedi"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dimanche"])}
  },
  "dialog": {
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
  },
  "profile": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de L'utilisateur"])},
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails personnels"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
    "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Token"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Actif"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])}
  },
  "contacts": {
    "searchAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search account"])},
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search company"])},
    "searchName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "searchNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search number"])},
    "contactAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact added successfully"])},
    "contactDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact deleted successfully"])},
    "addContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajouter une entrée"])},
    "uploadContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajouter plusieurs entrées (fichier)"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société"])},
    "identificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'identification du client"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "vip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client VIP"])},
    "onCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client sous contrat d'astreinte"])}
  },
  "pbx": {
    "newGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Groupe"])},
    "extensionNewGroupForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire pour créer un groupe d'extension"])},
    "extensionFormSubtittle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les information du nouveau groupe ci-dessous"])},
    "queueNewGroupForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire pour créer un groupe de file d'appels"])},
    "queueFormSubtittle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les information du nouveau groupe ci-dessous"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autres sélections..."])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])}
  },
  "pbxReport": {
    "shareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager ce rapport"])},
    "shareMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez partager ce rapport avec les paramètres suivants"])},
    "shareScheduleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager un rapport planifié"])},
    "shareScheduleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez partager un rapport planifié avec les paramètres suivants"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter les destinataires pour compléter l'envoi. Si aucun email n'est fourni, le rapport vous sera envoyé."])},
    "inputError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournissez une email valide ou une adresse ne figurant pas dans la liste ci-dessous."])},
    "nicknamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du rapport"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport envoyé avec succès"])}
  }
}