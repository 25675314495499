<template>
  <header class="w-full bg-white shadow">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="flex justify-end">
        <LanguageSelector />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

import LanguageSelector from "../components/LanguageSelector.vue";

const avatar = localStorage.getItem("avatar");
const logo = localStorage.getItem("logo");

export default {
  props: [],
  components: {
    LanguageSelector,
  },
  data() {
    return {
      avatar,
      search: "",
      quickData: [],
      logo,
      searchableTerm: "",
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style></style>
