<template>
 <div class="relative bg-white overflow-hidden">
  <div class="max-w-7xl mx-auto">
   <div
    class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
   >
    <svg
     class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
     fill="currentColor"
     viewBox="0 0 100 100"
     preserveAspectRatio="none"
     aria-hidden="true"
    >
     <polygon points="50,0 100,0 50,100 0,100" />
    </svg>

    <main class="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-20">
     <div class="sm:text-center lg:text-left">
      <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
       <div class="sm:flex sm:justify-center lg:justify-start">
        <img
         src="https://storage.gra.cloud.ovh.net/v1/AUTH_9c30d35f284f44b2bda08609e7c19f33/bluerocktelclients/telecom0525/logo-horizontal.jpg"
         alt=""
         class="h-28 w-34 -ml-10 "
        />
       </div>
       <span class="block xl:inline">
        {{ $t("thankYouTitle") }}
       </span>
       {{ " " }}
       <span class="block primary_text xl:inline">
        {{ $t("thankYouHeadline") }}
       </span>
      </h1>
      <p
       class="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
      >
       {{ $t("thankYouParagraph") }}.
      </p>
      <br />
      <h2 class="text-base text-gray-600 sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl lg:mx-0">
       {{ $t("nextStep") }} ?
      </h2>
      <p class="text-base text-gray-500 sm:text-lg sm:max-w-xl sm:mx-auto lg:mx-0">
       {{ $t("nextStepParagraph") }}.
      </p>
      <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
       <DescriptionList :data="data ? data : ''" />
      </div>
     </div>
    </main>
   </div>
  </div>
  <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
   <img
    class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
    src="../assets/logos/c93134e2-3434-464e-b6e0-e393a07e7955.jpg"
    alt=""
   />
  </div>
 </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import AskConfirmationDialog from "../components/AskConfirmationDialog";
import CollectMainInfos from "../components/CollectMainInfos";
import DescriptionList from "../components/formTools/DescriptionList.vue";
import HeaderSection from "../components/HeaderSection";
import SuccessBanner from "../components/SuccessBanner.vue";

export default {
 name: "ThankYou",
 props: ["closingCollect"],
 components: {
  AskConfirmationDialog,
  CollectMainInfos,
  DescriptionList,
  HeaderSection,
  Loading,
  SuccessBanner,
 },

 data() {
  return {
   activeBanner: false,
   data: {},
   isSuccess: true,
   isLoading: true,
   key: false,
   successMsg: "",
  };
 },
 methods: {},
 mounted() {
  this.data = JSON.parse(this.$route.params.closingCollect);
 },
 computed: {},
 watch: {},
};
</script>

<style></style>
