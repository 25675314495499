export default {
  "associateNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["associated number"])},
  "adminExt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin extension"])},
  "errorOnFinalFormFqdn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your domain"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
  "carriedNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one of your carried numbers"])},
  "homePageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your installation in a few steps"])},
  "homePageHeaderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your trust that you bring us. You can start your installation here"])},
  "customerInformationCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information collection"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participants"])},
  "wishTocontinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to continue ?"])},
  "openingDaysAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opening days and time"])},
  "strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strategy"])},
  "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
  "bankDetailsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your bank details"])},
  "doItLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do it later"])},
  "predecroche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predecroche"])},
  "predecrocheAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predecroche agent"])},
  "destNoResp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destination no response"])},
  "nonAnsweringDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non answering destination"])},
  "adminNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin notifications"])},
  "isComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is complete"])},
  "generalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General settings"])},
  "strategyClosingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategy for closing hours"])},
  "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced settings"])},
  "callqueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call queues"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order"])},
  "needAtLeastOneContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need at least one contact"])},
  "needAtLeastOneAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need at least one address"])},
  "callQueueOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call queue order"])},
  "callQueueOrderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use arrows or drag to select preferred elements order in queue"])},
  "attentionNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention needed"])},
  "addGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add groups"])},
  "addGroupsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven’t added any extensions group to your collect yet. You can start now and manage their permissions"])},
  "usersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A list of all users in the group, with their name, extension and rights"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "wrongRioFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong RIO format"])},
  "provideCorrectNumberOrRio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide correct phone number and/or correct RIO"])},
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome message"])},
  "toBeCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to be completed"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "deleteMessageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete item"])},
  "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you delete this item you may lose information from forms already completed. Do you wish to continue ?"])},
  "errorFormNumberInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This selection create conflict to an existed one."])},
  "errorFormNumberFromSmallerThanTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start of the range can't be smaller than the end."])},
  "errorFormNumberFromAndToEqual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to add a single number, use Isolated Numbers instead."])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "sviSdaLinkedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide one or more numbers to associate"])},
  "notAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to access to this content."])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "otherInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other information"])},
  "otherInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add this recipient to a list"])},
  "updateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update user"])},
  "updateUserDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new informations below"])},
  "expeditorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expeditor name"])},
  "expeditorNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name displayed to the recipient when receiving SMS"])},
  "smsTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of SMS"])},
  "smsMessageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the content of the SMS"])},
  "recipientInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the informations of the new recipient here"])},
  "recipientListInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the informations of the new list here"])},
  "disactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disactivate"])},
  "marketingDisclaimerPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful ! For all marketing SMS, the message must finish by"])},
  "marketingDisclaimerPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" It's mandatory in order to provide your recipients with a means of unsubscribing and to comply with the obligations of the CNIL"])},
  "recipientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Information"])},
  "recipientInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the recipients of your SMS here. You can also combine this with a recipients list"])},
  "campaignNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your campaign name"])},
  "signinToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your account"])},
  "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "newPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It must contain one uppercase, one lowercase, one number"])},
  "ownedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owned by"])},
  "ownerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner email"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ready"])},
  "newRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new recipient"])},
  "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new account"])},
  "newAccountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create new account by completing form below"])},
  "newList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new list"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
  "cantBeEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can not be empty"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing"])},
  "billingMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing mode"])},
  "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
  "updateOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update organisation"])},
  "updateCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update SMS Campaign"])},
  "organisationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Information"])},
  "organisationInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation you related and details about it"])},
  "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campaign"])},
  "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campaigns"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recipient"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])},
  "recipientsLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients Lists"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accounts"])},
  "goodMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good morning"])},
  "goodAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good afternoon"])},
  "goodEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good evening"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marketing"])},
  "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choice"])},
  "campaignReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign ready"])},
  "campaignInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign information"])},
  "campaignDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign detail and statistics"])},
  "newCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new campaign"])},
  "newOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New organisation"])},
  "newOrganisationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create new organisation by completing form below"])},
  "accountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the account for this campaign"])},
  "smsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of SMS"])},
  "autoCallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto callback"])},
  "autoCallbackDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The callback option allows customers who do not wish to wait to be called back. 3CX will read the customer's number and offer to call them back on that number, or to change the number, or to continue waiting. If they choose to call back, 3CX will read an acceptance message and hang up. As soon as an agent in the queue is available, 3CX automatically calls the available agent to connect with the customer"])},
  "autoCallbackDescriptionYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requires a pre-hook informing the customer that they can request a callback by pressing the 2 key on their keyboard at any time during the wait"])},
  "autoCallbackDescriptionNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["option disabled"])},
  "autoCallbackTypeDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the tempo in seconds. The tempo duration includes the pre-hook, for example if the pre-hook lasts 30 sec and the auto-callback tempo is 45 sec, the customer will be offered the auto-callback only after 15 sec of waiting. You can provide us with a studio message for the auto callback announcement or use the default 3CX message"])},
  "associatedQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue(s) associated with the message"])},
  "associatedExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension(s) associated with the message"])},
  "associatedNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number(s) associated with the message"])},
  "useMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use message"])},
  "voiceChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice choice"])},
  "musicChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music choice"])},
  "typeYourMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your different messages here"])},
  "phonetic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phonetic clarification of words subject to interpretation"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importer"])},
  "importUserWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing your user directory involves deleting previously created users, this feature is subject to improvement in the near future"])},
  "importFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import file"])},
  "importFileDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the possibility to import a file containing your entire user directory"])},
  "importFileDetail2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the format of the file to be completed, then imported"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drag and drop"])},
  "notRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggest to the caller not to be registered via key 3"])},
  "notRecordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you record incoming calls, you can suggest to callers to not record the call"])},
  "notRecordYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key 3 is activated. Your pre-hook message should inform the caller that they have the option of not being recorded by dialing key 3 on their phone"])},
  "notRecordNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key 3 is disabled. The pre-hook message should state that the caller only has the option of asking the other party not to record the conversation"])},
  "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded by"])},
  "uploadedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded at"])},
  "uploadAFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
  "adminQueueExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue administrator(s) extension"])},
  "userQueueExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension number of group members"])},
  "nonWorkingTimeDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination in non-working hours"])},
  "audioMessageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio message number"])},
  "messageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message name"])},
  "strategyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanning strategy"])},
  "prehook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-hook"])},
  "prehookDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate on the right the number of the pre-hook message (see MESSAGE STUDIO ) or the name of the message provided by you"])},
  "prehookBeforeCallAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play pre-hook before ringing agent"])},
  "allDestinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All open destinations"])},
  "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activated"])},
  "activateRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All external calls, incoming and outgoing, are recorded"])},
  "desactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desactivated"])},
  "ivsDescriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be put in touch with the sales department, dial 1, to be put in touch with the technical department dial 2, etc."])},
  "ivsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive Voice Server (IVS)  improves the quality and efficiency of your telephone reception. A pre-hook message allows incoming calls to be directed directly to the right person or to a dedicated voice mailbox"])},
  "ivsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVS List"])},
  "ivsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVS Name"])},
  "messageNameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This queue name is already being used"])},
  "desactivateRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No calls are recorded"])},
  "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
  "manualRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call recording is initiated manually"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
  "addressDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add one or more geographic addresses"])},
  "availableFromVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available from version"])},
  "contactDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add and indicate contacts, including"])},
  "contactDetailList1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The technical contact for the deployment of 3CX"])},
  "contactDetailList2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The accounting contact for receiving and processing invoices"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "errorOnFinalFormIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, provide a correct IBAN"])},
  "errorOnFinalFormBIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, provide a correct SWIFT/BIC"])},
  "calls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calls"])},
  "requestSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications effectuées avec succès"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "strategyOpeningHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategy opening hours"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
  "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calls recording"])},
  "recordingDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This service is subject to GDPR compliance"])},
  "recordingDisclaimerDetail1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A predisclaimer of the type"])},
  "recordingDisclaimerDetail2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is mandatory to enable recordings. To keep recordings for more than 1 month"])},
  "recordingDisclaimerSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your conversation may be monitored or recorded"])},
  "recordingDisclaimerList1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate how long you want to store the messages in the comment section"])},
  "recordingDisclaimerList2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription to a storage option longer than 1 month is subject to a charge and must be indicated on the order form"])},
  "newQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New queue"])},
  "callQueueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callqueue name"])},
  "callsRestrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction on other destinations activated: indicate in the comment section the other restrictions for each user or user group"])},
  "noCallsRestrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction on other destinations disabled"])},
  "signatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatory"])},
  "signatoryContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatory"])},
  "legalRepresentative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatory"])},
  "technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical"])},
  "technicalContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Contact"])},
  "accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountant"])},
  "accountingContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting Contact"])},
  "invoicesRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting Contact"])},
  "physicalSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical sites"])},
  "physicalSiteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical sites name"])},
  "intercallDelayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause time between the reception of 2 calls. Useful for taking notes at the end of an interview or closing a CRM form"])},
  "intercallDelay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inter-call delay. (in seconds)"])},
  "priorityQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority queue"])},
  "priorityQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case agents belong to several queues, the calls of the priority queue will be distributed in priority to the agents"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State / Province"])},
  "thereWere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There were"])},
  "youUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've uploaded"])},
  "youCanAddByClicking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add them by clicking on the button"])},
  "successfullyUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully uploaded"])},
  "below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["below"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid e-mail address or password"])},
  "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["errors"])},
  "oneOrMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one or more"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["none"])},
  "switchingTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching times in seconds"])},
  "errorWithYourSubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error with your submission"])},
  "trainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainings"])},
  "trainingMandateHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training paid by your OPCO"])},
  "trainingUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Training"])},
  "trainingAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Training"])},
  "miscellaneous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miscellaneous"])},
  "webClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Client"])},
  "webClientDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows the user to connect to the 3CX management interface via a web page"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "waitingQueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting Queues"])},
  "waitingQueuesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues allow calls to be put on hold until agents (members of a queue) can answer the calls."])},
  "waitingQueuesDescriptionOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues allow calls to be put on hold until agents (members of a queue) can answer the calls. Calls are not left unanswered, they are put on hold. For example, it is possible to set up a group of 3 salespeople, and have the sales department's number routed to a specific queue for the sales department. If all 3 sales representatives are busy, the callers will be queued until a sales representative becomes available. This table allows you to define the operation of one or more call queues"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
  "yesPredecrocheAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The extension will only start ringing when the pre-hook message is completed"])},
  "noPredecrocheAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The extension will start ringing as soon as the prehook message starts playing"])},
  "predecrocheMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music on hold after pre-hook"])},
  "predecrocheMusicDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate on the right the name of the music on hold after pre-hook"])},
  "maxCallerNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of callers in the queue"])},
  "openTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening days and times"])},
  "openTimeSlotDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add opening days and hours"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
  "noResponseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time until destination if no answer. (in seconds)"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "editItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit item"])},
  "editItemMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you edit this item you may lose information from forms already completed. Do you wish to continue?"])},
  "editItemMessageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to keep the information already existent click on cancel."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "outputDisplayNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output display number"])},
  "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations about the user"])},
  "personalInformationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global information about the user"])},
  "extensionMandateDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 to 5 digit formats (excluding 112 reserved for emergencies)"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "beNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I wish to be notified by e-mail"])},
  "notBeNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not wish to be notified by e-mail"])},
  "notificationMandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decide which notifications the user will receive by email"])},
  "missedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missed calls not notified/notified"])},
  "missedCallsDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are sent by email to the above address"])},
  "voicemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsent/sent voicemail messages"])},
  "voicemailDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email notification with audio file attachment"])},
  "hotdesking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows the user to connect to a physical station dedicated to the"])},
  "hotdeskingDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hot desking station is a station that is not assigned to any fixed user and allows multiple mobile users to connect to it using a pin code"])},
  "ipPhoneModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate a telephone set with the user"])},
  "restrictionsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decide which destinations the user can't call"])},
  "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
  "unsavedChangesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved changes"])},
  "unsavedChangesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unsaved changes ! If you leave now, unsaved changes will be discarded and your work will be lost."])},
  "forbiddenExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to use this extension."])},
  "unsavedChangesMessageOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Cancel to return to the Collection and save your new changes"])},
  "unsavedChangesCancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave without saving"])},
  "unsavedChangesOkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "invoicesDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This contact should receive monthly invoices by email"])},
  "contactAdminDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This contact is the administrator of the Encom Customer Area"])},
  "emailErrorAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address has already been used"])},
  "emailErrorFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow format"])},
  "followFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow format"])},
  "errorIsInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a number inside a provided range"])},
  "numbAlreadyAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number has already been added."])},
  "errorCreatedExtensionsLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This extension must contain as many digits as previously registered."])},
  "theEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email"])},
  "internationalCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Calls"])},
  "internationalCallDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the destinations for which international calls are allowed"])},
  "internationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select permitted destinations"])},
  "restrictionsInternationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions on international calls"])},
  "internationalDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify destinations to open"])},
  "restrictionsActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The list of authorised countries is defined in the first part of the Collect"])},
  "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "passwordResetMustContain12Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least 12 characters"])},
  "passwordResetMustContainOneUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least one uppercase letter"])},
  "passwordResetMustContainOneSpecialCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least one special character"])},
  "passwordResetMustContainOneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least one number"])},
  "bannerSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now in the sandbox application."])},
  "bannerProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now in production."])},
  "callQueueNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["callqueue number"])},
  "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call queue"])},
  "queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queue"])},
  "callFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call flow"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
  "deleteSvi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete SVI"])},
  "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "listsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list name"])},
  "reportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report list"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload"])},
  "mainInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Infos"])},
  "numbersPortability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers & portability"])},
  "numbersPortabilityTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers portability"])},
  "numbersPortabilityDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number portability allows you to keep your current numbers. Please indicate the numbers you wish to carry"])},
  "rangeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range of numbers to carry"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
  "numberIsolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isolated Numbers (Analog Line, Fax, VoIP Number)"])},
  "sda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "sdaCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create numbers"])},
  "sdaCreationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to create new numbers, please indicate the number of numbers to be created and the city where they will be used"])},
  "sdaCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of numbers to be created"])},
  "sdaCreateDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per 5 numbers minimum"])},
  "sdaLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number(s) linked"])},
  "numberToAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number(s) to assign to the user"])},
  "internalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension to be associated to the user"])},
  "sdaOrderedLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers ordered left"])},
  "sdaOrderedLeftDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your number or order a new one"])},
  "newNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new number"])},
  "ownNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an own number"])},
  "portabilityMandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portability mandate"])},
  "portabilityMandateDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1) Download here the portability mandate to be completed (2) Check and sign your mandate (3) Import your mandate"])},
  "portabilityMandateAndInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portability mandate(s) and invoice(s)"])},
  "portabilityMandateDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mandate must have the same company name and address as the invoice of your previous provider"])},
  "telephoneOperatorInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone operator invoice"])},
  "telephoneOperatorInvoiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please import a copy of your operator invoices (less than 3 months old) showing the numbers to be ported and the header numbers"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
  "usersCollectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section allows you to define the extensions and rights associated with each user"])},
  "usersMandateHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The extensions 12, 15, 17, 18 and 112 are reserved for emergency numbers"])},
  "siteAndNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site & Network"])},
  "customerRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Relations"])},
  "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
  "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Account"])},
  "EndCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "confirmCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Collect"])},
  "lostChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes are lost"])},
  "extensionAlreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This extension is already used."])},
  "informationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations saved"])},
  "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notice"])},
  "legalNoticeDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for taking the time to complete this document"])},
  "privacyGdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité et RGPD"])},
  "privacyGdprDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy and GDPR"])},
  "privacyGdprToogleLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By checking below, you confirm that you are aware of the GDPR regulation and what it entails"])},
  "privacyGdprComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any additional information on the configuration you want, you can fill in this field"])},
  "goBackToDependentStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete this step first"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension Settings"])},
  "globalSettingsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information about your 3CX"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "3cxDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3CX Domain"])},
  "yourDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Domain"])},
  "3cxDomainType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL to connect to your 3CX domain"])},
  "3cxDomainRoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3CX Root domain"])},
  "opco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your OPCO"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street number and name"])},
  "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP / Postal"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "pleaseCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please correct"])},
  "numberOfCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calls"])},
  "numberOfAnsweredCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answered"])},
  "callsAnsweredByAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answered by another"])},
  "callsTransferredByAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transferred by agent"])},
  "abandonedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abandoned"])},
  "callsDeterred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deterred"])},
  "averageWaitingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average waiting time"])},
  "solicited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solicited"])},
  "averageWaitingTimeQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average waiting time in queue"])},
  "dropoutTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average dropout time"])},
  "closeCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close collect"])},
  "closeCollectPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close information collections ?"])},
  "closeCollectPopupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are going to close this form, are you sure you want to continue ?"])},
  "closeCollectPopupInfoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By closing this form, you will accept the details you provided before and those informations will be sent to create the Collect."])},
  "informationWasUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information was updated on"])},
  "averageCallDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average call duration"])},
  "queueDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["queue detail"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
  "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numbers"])},
  "didCallers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DID Callers"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group"])},
  "resumeInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations summary"])},
  "resumeInformationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of informations sent earlier"])},
  "contactsLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total contacts added"])},
  "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])},
  "requestIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
  "finishedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished at"])},
  "thanksForValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for the validation"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
  "thankYouTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sent"])},
  "thankYouHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully"])},
  "thankYouParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Thank you to have filled out this informations collection"])},
  "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the next step"])},
  "nextStepParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will soon receive your e-contract to sign, by e-mail, to confirm your installation resquest"])},
  "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group"])},
  "deleteStudioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete studio message"])},
  "groupDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group deleted successfully"])},
  "userDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User deleted successfully"])},
  "sviDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SVI deleted successfully"])},
  "studioMessageDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio message deleted successfully"])},
  "groupsQueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups queues"])},
  "groupsExtensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups extensions"])},
  "queueComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue comparison"])},
  "numberOfRepeatedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repeated calls"])},
  "answeredCallsCoefficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answered calls coefficient"])},
  "abandonedCallsCoefficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abandoned calls coefficient"])},
  "dissuadedCallsCoefficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deterred calls coefficient"])},
  "comparisonSamePeriodLastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison same period last"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
  "noCallersForThisPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No callers found for this period"])},
  "pleaseSelectTwoQueuesForComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select two queues for comparison"])},
  "queueHasNoData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected queue has no data for this period"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
  "comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comparison"])},
  "compareTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "averageAnswered_00_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calls answered in up to 30 seconds"])},
  "averageAnswered_31_60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calls answered between 31 and 60 seconds"])},
  "averageAnswered_61_90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calls answered between 61 and 90 seconds"])},
  "averageAnswered_90+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calls answered in more than 90 seconds"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schedule"])},
  "scheduleReportEditDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to setup a schedule share report ?"])},
  "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created at"])},
  "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated at"])},
  "mandateIdentification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate Identification"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "reportDetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Information"])},
  "reportEditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
  "reportEditTitleDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General configuration of the rapport"])},
  "reportDetailTitleDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global detail and parameters of the report"])},
  "pbxHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX host"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "emailAddressReportEditDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage people who receive the report"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preview"])},
  "previewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can preview the presentation by clicking to the button below"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
  "cronDaily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report will be shared every day, at 04:00"])},
  "cronWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report will be shared every Monday, at 04:00"])},
  "cronMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report will be shared every 1st of the month, at 04:00"])},
  "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
  "noReportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No report found"])},
  "noReportDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new statistics dashboards"])},
  "noHostName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has no hosts"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "collectionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections list"])},
  "filesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files list"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
  "fileInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Information"])},
  "fileInformationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of the customer file and collections"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "newCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New collection"])},
  "studioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio messages"])},
  "studioMessageExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examples of pre-pickup messages"])},
  "studioMessageCreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to add a new message?"])},
  "CRMIntegrationQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to integrate your CRM/ERP?"])},
  "CustomerRelationModules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer relation modules"])},
  "SmartRouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart routing"])},
  "CustomerSatisfacionSurvey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer satisfaction survey"])},
  "VoiceRecognition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice recognition"])},
  "LiveWebChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live web chat"])},
  "WebCallBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web call back"])},
  "PushSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push SMS"])},
  "selectModules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select modules to be implemented"])},
  "CustomerRelationFormInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With 3CX you can benefit from CRM, ERP or accounting system integration with your PBX to be able to make calls to your contacts with a single click from your applications. In addition, incoming calls are automatically linked to a customer account that opens on your screen and all calls are logged in your CRM."])},
  "warningTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
  },
  "expertStatisticsNavigation": {
    "advancedStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Statistics"])},
    "origins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origins"])},
    "originsTop10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origins Top 10"])},
    "hourlyStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly Statistics"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "answeredUnansweredCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered & Unanswered"])},
    "reportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report List"])}
  },
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we couldn't find the page you're looking for..."])},
  "goBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back home"])},
  "sidebar": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "tickets": {
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])}
    },
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "subMenu": {
      "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial"])},
      "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues"])}
    },
    "specialNavigation": {
      "expertService3CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Service 3CX"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Routing"])},
      "georouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geographical Routing"])},
      "callqueuegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues Groups"])},
      "callqueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues"])},
      "reportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report List"])}
    }
  },
  "header": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchTicketPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject, created date or updated date"])},
    "searchInvoicePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice, date or total"])},
    "searchContactPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company, name, code or numbers"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Account"])},
    "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Ticket"])},
    "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Profile"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impersonate"])},
    "leaveImpersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave Impersonate"])}
  },
  "notificationBanner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application has new features! Open to see more."])},
    "learnMoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
  },
  "impersonate": {
    "popupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter User email to Impersonate"])}
  },
  "chartTable": {
    "callType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Type"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "avgPerCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg per Call"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special"])},
    "callCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call count"])},
    "callDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call duration"])},
    "fileNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File number"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "choosePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose period"])},
    "chooseResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose resource"])},
    "resourceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource number"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])},
    "data": {
      "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
      "unanswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered"])}
    }
  },
  "dashboards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "fullDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full day"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
    "concatenate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consolidate data"])},
    "concatenateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consolidated data for elements:"])},
    "chartHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics for period"])},
    "searchExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search extensions"])},
    "searchQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search queue"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
    "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
    "monthlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Dashboard of"])},
    "monthlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get monthly data of"])},
    "dailyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Dashboard of"])},
    "dailyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get daily data of"])},
    "hourlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly Dashboard of"])},
    "hourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get hourly data of"])},
    "donutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Answered & Unanswered Calls Count"])},
    "stackedColumnCategories": {
      "incomingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Count"])},
      "outgoingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Count"])},
      "answeredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered Count"])},
      "unansweredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered Count"])},
      "incomingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Duration"])},
      "outgoingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Duration"])},
      "answeredDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered Duration"])}
    },
    "error": {
      "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
      "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data for this period was not found or don't exist yet, we're sorry for the inconvenience."])}
    },
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unselect all"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear"])}
  },
  "warning": {
    "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
    "noResultFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
    "noResultFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try adjusting your result of filtering otherwise."])},
    "noDataFoundDocumentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Documents"])},
    "noDataFoundFilesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Files"])},
    "noDataFoundDocumentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any documents. Contact your line manager if it's not supposed to happen."])},
    "noDataFoundFilesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any files. Contact your line manager if it's not supposed to happen."])},
    "noDataFoundContactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Contacts"])},
    "noDataFoundContactDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new contact or by uploading a contacts list."])},
    "noDataFoundCtiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No CTI"])},
    "noDataFoundCtiDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new CTI configuration."])},
    "noDataFoundRoutingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Geographic Routing"])},
    "noDataFoundRoutingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new geographic routing."])},
    "noDataFoundCallQueueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Callqueues"])},
    "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new callqueue."])},
    "noDataFoundCallQueueGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Callqueue Groups"])},
    "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new callqueue group."])},
    "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on 'Add New Time Slot' button."])},
    "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on one of the 'Add Exception' buttons."])},
    "noDataFoundSMSListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SMS Lists"])},
    "noDataFoundSMSListDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new SMS list."])},
    "noDataFoundElementGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new group."])},
    "noDataFoundDashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Dashboard"])},
    "noDataFoundDashboardDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by selected elements and period."])},
    "mandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your number is linked to an internet subscription, number portability will automatically terminate this internet subscription and the associated analog support line. It is therefore important to check that no service depends on this analog line: payment terminal, alarm, fax, etc"])}
  },
  "pbxExtension": {
    "selectData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Data"])},
    "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Period"])}
  },
  "home": {
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])}
  },
  "ticket": {
    "ticketForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Form"])},
    "typeDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type down your questions/problems below"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Category"])},
    "selectProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Problem"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate resolution"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priority"])},
    "emailSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket subject"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "typeAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the problem above"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "writeReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your reply for the ticket"])},
    "concerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concerns"])}
  },
  "invoiceTable": {
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice ID"])},
    "invoiceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Number"])},
    "totalWithoutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Without Tax"])},
    "totalWithTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total With Tax"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "searchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search ID"])},
    "searchNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search number"])},
    "searchMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "searchYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY"])},
    "searchAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search amount"])}
  },
  "ticketTable": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priority"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select rows"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows"])},
    "confirmationCloseMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close the ticket?"])},
    "confirmationOpenMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reopen the ticket?"])},
    "closeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket closed successfully"])},
    "openMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket opened successfully"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action successful"])},
    "dialogTitleClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close ticket"])},
    "dialogTitleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open ticket"])},
    "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "searchStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search status"])},
    "searchDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY"])},
    "ticketList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets List"])}
  },
  "documentTable": {
    "allDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All documents"])},
    "yourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your documents"])}
  },
  "callqueue": {
    "buttonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new call queue"])},
    "buttonTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new call queue group"])},
    "newFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call queue form"])},
    "newFormTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call queue group form"])},
    "updateException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update exception"])},
    "updateTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update call queue group"])},
    "updateFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update call queue"])},
    "updateFormTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update call queue group"])},
    "formSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new call queue information below."])},
    "formSubTitleGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new call queue group information below."])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update by completed informations below"])}
  },
  "callqueueTable": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
    "weekdayAndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week days or date"])},
    "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week days"])},
    "specificDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific date"])},
    "timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time slot"])},
    "exception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exceptions"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reference"])},
    "addTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add time slot"])},
    "addException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add exception"])},
    "addExceptionTwelveMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add bank holidays for 12 next months"])},
    "formTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a day or day range and the time slots associated"])},
    "formExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add a callqueue exception by selecting a specific day"])},
    "formExceptionTwelveMonthsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the next twelve bank holidays for France"])},
    "selectAllDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All week days"])},
    "selectAllWorkingDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Working days"])},
    "inputSelectDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select mutiple days by using"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "dateSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Selected"])},
    "daySelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Selected"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
    "checkStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start can't be between an existed time slot"])},
    "checkEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end can't be between an existed time slot"])},
    "checkBetween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This timeslot make conflit with existed one"])},
    "pickedStartBigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start can't be after or the same than the end"])},
    "newExceptionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New exception date picked"])},
    "newExceptionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, there is an error with your selected date"])},
    "newExceptionExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception date already exist"])},
    "selectExceptionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an exception date in the calendar before to continue"])},
    "destinationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify a destination here. The API returns information about the opening or closing of the call queue according to the schedule. Do not specify a destination here if your call flow calls the API later to route the call (geographic or intelligent routing...)"])},
    "nextFrenchHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Bank Holidays over 12 Months"])},
    "addFrenchHolidaysWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will automatically add exceptions for all the dates specified above"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to continue ?"])}
  },
  "weekdays": {
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monday"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuesday"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wednesday"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thurday"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friday"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saturday"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunday"])}
  },
  "dialog": {
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
  },
  "profile": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Information"])},
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal details"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Token"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Account"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])}
  },
  "contacts": {
    "searchAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search account"])},
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search company"])},
    "searchName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "searchNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search number"])},
    "contactAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact added successfully"])},
    "contactDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact deleted successfully"])},
    "addContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new contact"])},
    "uploadContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload contacts"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "identificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client code identification"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "vip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP"])},
    "onCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-call contact"])}
  },
  "pbx": {
    "newGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New group"])},
    "extensionNewGroupForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension group form"])},
    "queueNewGroupForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue group form"])},
    "extensionFormSubtittle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new extension group information below"])},
    "queueFormSubtittle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new queue group information below"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more elements..."])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
  },
  "pbxReport": {
    "shareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share a report"])},
    "shareMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You want to share the report with the following settings"])},
    "shareScheduleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share a scheduled report"])},
    "shareScheduleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You want to share a scheduled report with the following settings"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add the recipients to complete the action. If no email is provided, the report will be send to you."])},
    "inputError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide a valid email address or one not listed below."])},
    "nicknamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write report name here"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report sent successfully"])}
  }
}