<template>
  <popup-modal ref="popup">
    <div
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <div
          class="rounded-md inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
        >
          <div class="sm:flex sm:items-start">
            <div
              :class="[
                this.action === 'delete'
                  ? `${this.alertColorBG}`
                  : `encom_primary`,
                `mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10`,
              ]"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                {{ $t(title) }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ $t(message) }}
                </p>
              </div>
              <div class="rounded-md bg-blue-50 py-2 px-1" v-if="infoMessage">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon
                      class="h-4 w-4 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-1 flex-1 md:flex md:justify-between">
                    <p class="text-xs text-blue-700">
                      {{ $t(infoMessage) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
            <button
              @click="_confirm"
              type="button"
              :class="[
                this.action === 'delete'
                  ? `${this.alertColorButtonText} ${this.alertColorButton} ${this.alertColorButtonHover}`
                  : `encom_primary text-white encom_primary_hover`,
                `rounded inline-flex justify-center w-full border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:w-auto sm:text-sm`,
              ]"
            >
              {{ $t(okButton) }}
            </button>
            <button
              @click="_cancel"
              type="button"
              class="rounded mt-3 w-full inline-flex justify-center border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {{ $t(cancelButton) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import { mapGetters } from "vuex";
import PopupModal from "./PopupModal.vue";
import { InformationCircleIcon } from "@heroicons/vue/solid";

export default {
  name: "AskConfirmationDialog",

  components: { PopupModal, InformationCircleIcon },

  data: () => ({
    title: undefined,
    message: undefined, // Main text content
    infoMessage: undefined, // Sub text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Go Back", // text for cancel button
    action: "",
    alertColorText: "text-red-500",
    alertColorButtonText: "text-white",
    alertColorButtonHover: "hover:bg-red-600",
    alertColorButton: "bg-red-500",
    alertColorBG: "bg-red-100",
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    infoMessage: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.infoMessage = opts.infoMessage;
      this.okButton = opts.okButton;
      if (opts.alertColorText) {
        this.alertColorText = opts.alertColorText;
      }
      if (opts.alertColorButtonText) {
        this.alertColorButtonText = opts.alertColorButtonText;
      }
      if (opts.alertColorButtonHover) {
        this.alertColorButtonHover = opts.alertColorButtonHover;
      }
      if (opts.alertColorButton) {
        this.alertColorButton = opts.alertColorButton;
      }
      if (opts.alertColorBG) {
        this.alertColorBG = opts.alertColorBG;
      }
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      if (opts.action) {
        this.action = opts.action;
      }
      if (opts.infoMessage) {
        this.infoMessage = opts.infoMessage;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$emit("closeForm");
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style></style>
