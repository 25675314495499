import { createStore } from "vuex";
import { VueCookieNext } from "vue-cookie-next";

export default createStore({
  state: {
    user: {},
    isAuthenticated: false,
    avatar: localStorage.getItem("avatar") || "",
    token: localStorage.getItem("token") || null,
    language: localStorage.getItem("language") || "en",
    background: localStorage.getItem("background") || null,
    ticketCount: {},
    logo: localStorage.getItem("logo") || null,
    userId: localStorage.getItem("userId") || null,
    admin: localStorage.getItem("admin") || 0,
    ticketTableHeader: JSON.parse(
      localStorage.getItem("tableHeadersStorage")
    ) || [
      { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
      { name: "ticketTable.client", isSort: false, show: true },
      {
        name: "ticketTable.status",
        sort: "status_id",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.priority",
        sort: "priority_id",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.createdDate",
        sort: "created_at",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.updatedDate",
        sort: "updated_at",
        isSort: false,
        show: true,
      },
      {
        name: "resDateShort",
        sort: "estimatedResolutionDate",
        isSort: false,
        show: true,
      },
      { name: "assign", show: true },
      { name: "captureFree", show: true },
    ],
    ticketTableHeadersSelect: JSON.parse(
      localStorage.getItem("tableHeadersSelectStorage")
    ) || [
      { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
      { name: "ticketTable.client", isSort: false, show: true },
      {
        name: "ticketTable.status",
        sort: "status_id",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.priority",
        sort: "priority_id",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.createdDate",
        sort: "created_at",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.updatedDate",
        sort: "updated_at",
        isSort: false,
        show: true,
      },
      {
        name: "resDateShort",
        sort: "estimatedResolutionDate",
        isSort: false,
        show: true,
      },
      { name: "assign", show: true },
      { name: "captureFree", show: true },
    ],
    openRemovedHeaders:
      JSON.parse(localStorage.getItem("removedHeadersStorage")) || [],
    closedRemovedHeaders:
      JSON.parse(localStorage.getItem("closedRemovedHeadersStorage")) || [],
    closedTicketTableHeader: localStorage.getItem(
      "closedTicketTableHeaderStorage"
    ) || [
      { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
      { name: "ticketTable.client", isSort: false, show: true },
      {
        name: "ticketTable.status",
        sort: "status_id",
        isSort: false,
        show: false,
      },
      {
        name: "ticketTable.priority",
        sort: "priority_id",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.createdDate",
        sort: "created_at",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.updatedDate",
        sort: "updated_at",
        isSort: false,
        show: true,
      },
      {
        name: "resDateShort",
        sort: "estimatedResolutionDate",
        isSort: false,
        show: true,
      },
      { name: "assign", show: true },
      { name: "captureFree", show: true },
    ],
    closedTicketTableHeaderSelect: localStorage.getItem(
      "closedTicketTableHeaderSelectStorage"
    ) || [
      { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
      { name: "ticketTable.client", isSort: false, show: true },
      {
        name: "ticketTable.status",
        sort: "status_id",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.priority",
        sort: "priority_id",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.createdDate",
        sort: "created_at",
        isSort: false,
        show: true,
      },
      {
        name: "ticketTable.updatedDate",
        sort: "updated_at",
        isSort: false,
        show: true,
      },
      {
        name: "resDateShort",
        sort: "estimatedResolutionDate",
        isSort: false,
        show: true,
      },
      { name: "assign", show: true },
      { name: "captureFree", show: true },
    ],
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    isAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    avatar(state, avatar) {
      state.avatar = avatar;
    },
    token(state, token) {
      state.token = token;
    },
    language(state, language) {
      state.language = language;
    },
    logo(state, logo) {
      state.logo = logo;
    },
    userId(state, userId) {
      state.userId = userId;
    },
    admin(state, admin) {
      state.admin = admin;
    },
    ticketTableHeadersSelect(state, ticketTableHeadersSelect) {
      state.ticketTableHeadersSelect = ticketTableHeadersSelect;
    },
    openRemovedHeaders(state, openRemovedHeaders) {
      state.openRemovedHeaders = openRemovedHeaders;
    },
    ticketTableHeader(state, ticketTableHeader) {
      state.ticketTableHeader = ticketTableHeader;
    },
    closedTicketTableHeader(state, closedTicketTableHeader) {
      state.closedTicketTableHeader = closedTicketTableHeader;
    },
    closedTicketTableHeaderSelect(state, closedTicketTableHeaderSelect) {
      state.closedTicketTableHeaderSelect = closedTicketTableHeaderSelect;
    },
    closedRemovedHeaders(state, closedRemovedHeaders) {
      state.closedRemovedHeaders = closedRemovedHeaders;
    },
    background(state, background) {
      state.background = background;
    },
  },
  actions: {
    isAuthenticated(context, isAuthenticated) {
      context.commit("isAuthenticated", isAuthenticated);
    },
    user(context, user) {
      context.commit("user", user);
    },
    userAvatar(context, avatar) {
      context.commit("avatar", avatar);
    },
    token(context, token) {
      context.commit("token", token);
    },
    language(context, language) {
      context.commit("language", language);
    },
    logo(context, logo) {
      context.commit("logo", logo);
    },
    userId(context, userId) {
      context.commit("userId", userId);
    },
    admin(context, admin) {
      context.commit("admin", admin);
    },
    ticketTableHeadersSelect(context, ticketTableHeadersSelect) {
      context.commit("ticketTableHeadersSelect", ticketTableHeadersSelect);
    },
    openRemovedHeaders(context, openRemovedHeaders) {
      context.commit("openRemovedHeaders", openRemovedHeaders);
    },
    ticketTableHeader(context, ticketTableHeader) {
      context.commit("ticketTableHeader", ticketTableHeader);
    },
    closedTicketTableHeader(context, closedTicketTableHeader) {
      context.commit("closedTicketTableHeader", closedTicketTableHeader);
    },
    closedTicketTableHeaderSelect(context, closedTicketTableHeaderSelect) {
      context.commit(
        "closedTicketTableHeaderSelect",
        closedTicketTableHeaderSelect
      );
    },
    closedRemovedHeaders(context, closedRemovedHeaders) {
      context.commit("closedRemovedHeaders", closedRemovedHeaders);
    },
    background(context, background) {
      context.commit("background", background);
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    avatar: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    language: (state) => {
      return state.language;
    },
    logo: (state) => {
      return state.logo;
    },
    userId: (state) => {
      return state.userId;
    },
    admin: (state) => {
      return state.admin;
    },
    openRemovedHeaders: (state) => {
      return state.openRemovedHeaders;
    },
    ticketTableHeadersSelect: (state) => {
      return state.ticketTableHeadersSelect;
    },
    ticketTableHeader: (state) => {
      return state.ticketTableHeader;
    },
    closedTicketTableHeader: (state) => {
      return state.closedTicketTableHeader;
    },
    closedTicketTableHeaderSelect: (state) => {
      return state.closedTicketTableHeaderSelect;
    },
    closedRemovedHeaders: (state) => {
      return state.closedRemovedHeaders;
    },
    background: (state) => {
      return state.background;
    },
  },
});
