export default {
  data() {
    return {
      time: "",
    };
  },
  methods: {
    timeFormater(time) {
      // if (time > 3600) {
      //   return new Date(time * 1000).toISOString().slice(11, 19);
      // } else {
      return new Date(time * 1000).toISOString().slice(11, 19);
      // }
    },
    toLocaleDateString(date) {
      const dateCreated = new Date(date);

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return dateCreated.toLocaleDateString("fr-FR", options);
    },
    toLocaleTimeString(time) {
      const dateCreated = new Date(time);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return dateCreated.toLocaleTimeString("fr-FR", options);
    },
    formatDateAndTime(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        dateCreated.getHours() +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
  },
};
