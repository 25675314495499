<template>
 <div class="mt-1 sm:col-span-3 sm:col-start-2">
  <div
   v-if="errors.isError || validationErrors.bool"
   :class="[showAll ? 'h-auto' : 'h-36', 'overflow-auto rounded-md bg-red-50 pt-4 my-2']"
  >
   <div class="flex px-4">
    <div class="flex-shrink-0">
     <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
    </div>
    <div class="ml-3">
     <h3 class="text-sm font-semibold text-red-800">
      {{
       errors.isError
        ? $t("errorImportUsers", { number: errors.count })
        : $t("errorImportUsers", { number: validationErrors.infos.length })
      }}
     </h3>
     <div class="mt-2 text-sm text-red-700">
      <ul v-if="errors.isError" role="list" class="list-disc pl-5 space-y-1">
       <li v-for="(error, idx) in errors.lists" :key="idx">
        {{ error }}
       </li>
      </ul>
      <ul v-else role="list" class="list-disc pl-5 space-y-1">
       <li v-for="(error, idx) in validationErrors.infos" :key="idx">
        {{ $t(error) }}
       </li>
      </ul>
     </div>
    </div>
   </div>
   <div v-show="errors.lists.length > 3" class="relative sticky bottom-0 text-center">
    <button
     type="button"
     @click="showAll = !showAll"
     class="mb-1 z-10 sticky inline-flex items-center px-2.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-200 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-500"
    >
     {{ !showAll ? $t("show") + " " + `${errors.count}` + " " + $t("errors") : $t("showLess") }}
    </button>

    <div class="z-0 absolute inset-0 bg-red-100 filter blur-sm"></div>
   </div>
  </div>
  <div v-else-if="isSuccess" class="rounded-md bg-green-50 p-4 my-2">
   <div class="flex">
    <div class="flex-shrink-0">
     <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
    </div>
    <div class="ml-3">
     <h3 class="text-sm font-semibold text-green-800">
      {{ $t("successfullyUploaded") }}
     </h3>
    </div>
   </div>
  </div>
  <div
   class="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-500"
   @drop="dropHandler($event)"
   @dragover="dragOverHandler($event)"
  >
   <div class="space-y-1 text-center">
    <svg
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 48 48"
     stroke="currentColor"
     aria-hidden="true"
    >
     <path
      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
     />
    </svg>
    <div class="flex justify-center text-sm text-gray-600">
     <label
      for="file-upload"
      class="relative cursor-pointer bg-white rounded-md font-medium encom_ouvert_ticket_text"
     >
      <span> {{ $t("uploadAFile") }}</span>
      <input
       id="file-upload"
       name="file-upload"
       type="file"
       ref="file"
       class="sr-only"
       accept="image/png, image/jpeg, image/jpg, application/pdf"
       @change="uploadFile($event)"
      />
     </label>
     <p class="pl-1">{{ $t("or") }} {{ $t("dragAndDrop") }}</p>
    </div>
    <div class="flex justify-center">
     <ExclamationCircleIcon
      v-show="errorFormMandateUpload"
      class="h-4 w-4 text-red-500 mr-1"
      aria-hidden="true"
     />
     <p :class="errorFormMandateUpload ? 'text-xs text-red-600' : 'text-xs text-gray-500'">
      RIB au format PNG, JPG, JPEG {{ $t("or") }} PDF {{ $t("accepted") }}.
     </p>
    </div>
    <p v-show="this.file.name" class="text-sm text-gray-700">
     <font-awesome-icon icon="file-import" class="h-4 w-4 text-gray-500" aria-hidden="true" />
     {{ this.file.name }}
    </p>
   </div>
  </div>
 </div>
 <!--  <div class="col-span-6 flex justify-end px-4 mt-2">
  <button
   @click="submitFile($t('noFileHasBeenUploaded'))"
   type="button"
   class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover encom_ring_color"
  >
   {{ $t("upload") }}
  </button>
 </div> -->
</template>

<script>
import axios from "axios";

import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";

import {
 CheckCircleIcon,
 ExclamationCircleIcon,
 ExclamationIcon,
 XCircleIcon,
} from "@heroicons/vue/solid";
import { useSlots } from "@vue/runtime-core";

const account = localStorage.getItem("account");

export default {
 name: "CollectImportUsers",
 props: ["token", "validationErrors"],
 components: {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  ToggleWithIcon,
  XCircleIcon,
 },

 data() {
  return {
   account,
   documents: [],
   errors: { isError: false, count: null, lists: [] },
   fullPage: true,
   form: {},
   file: "",

   isSuccess: false,
   newUsers: [],
   sdaMax: null,
   sdaLeft: null,
   sdaUsed: null,
   showAll: false,
   unvalidate: false,
   usersImported: null,
  };
 },

 methods: {
  uploadFile() {
   this.file = this.$refs.file.files[0];
   if (this.file) {
    this.submitFile();
    this.errorFormImport = false;
   } else {
    this.errorFormImport = true;
   }
  },
  dragOverHandler(ev) {
   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();
  },
  dropHandler(ev) {
   console.log("File(s) dropped");

   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();

   if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
     // If dropped items aren't files, reject them
     const type = ev.dataTransfer.items[i].type;

     if (
      type === "application/pdf" ||
      type === "image/jpg" ||
      type === "image/jpeg" ||
      type === "image/png"
     ) {
      var file = ev.dataTransfer.items[i].getAsFile();
      this.file = file;
      this.errorFormImport = false;
      this.submitFile();
     } else {
      this.errorFormImport = true;
     }
    }
    console.log(this.file);
   } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
     console.log("... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
    }
   }
  },
  submitFile(noFileMsg) {
   this.isSuccess = false;
   /* Initialize the form data */
   let formData = new FormData();
   if (this.file) {
    formData.append("iban_file", this.file);
    /* Add the form data we need to submit */

    this.$emit("setLoading", true);
    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/customer-information-collection/${this.token}/file`,
      formData,
      {
       headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then((res) => {
      let msg = this.$t("requestSuccess");

      this.errorChecking(res.status, res.data);

      this.$emit("setLoading", false);
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((err) => {
      let msg = this.$t("requestSuccess");

      console.error(err);

      this.$emit("setLoading", false);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", err.response.data.infos[0]);
      this.errorChecking(err.response.status, err.response.data);
     })
     .finally(() => {
      this.$emit("setLoading", false);
     });
   } else {
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", "noFileMsg");
   }
  },
  errorChecking(status, data) {
   this.errors.lists = [];

   if (status !== 201) {
    this.errors.isError = true;

    this.errors.count = data.infos.length;
    for (let index = 0; index < data.infos.length; index++) {
     const error = data.infos[index];
     this.errors.lists.push(error);
    }
    this.file = "";
   } else {
    this.errors.isError = false;
   }
  },

  formValidation() {
   if (this.usersImported > 0) {
    this.unvalidate = false;

    for (let index = 0; index < this.newUsers.length; index++) {
     const element = this.newUsers[index];
     element["isComplete"] = true;
    }
    this.$emit("newUsers", this.newUsers, this.sdaLeft, this.$t("userUploadedSuccessfully"));
   } else {
    this.unvalidate = true;
   }
  },
 },
 mounted() {},

 watch: {},
};
</script>
